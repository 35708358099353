<script>
import SidebarLink from './SidebarLink'
import httpClient from '../httpClient'

export default {
  name: 'Sidebar',
  components: {SidebarLink},
  data() {
    return {
      collapsed: false
    }
  },
  mounted() {

  },
  methods: {
    onboarding(){
      return this.$isOnboarding.value
    },
    toggleSidebar() {
      this.$emit('sidebaremit', (this.collapsed ? (this.$pluginKey.value === 'com.doitbi.bestbi.cloud' ? 190 : 185) : 50 ))
      this.collapsed = !this.collapsed
    },
    open() {
      this.$emit('openhelpglobal', true)
    },
    openHelpDialog() {
      AJS.$('#dark-block').css('display', 'block')
      AJS.$('#help-dialog').css('z-index', '1000')
      document.getElementById('help-dialog').setAttribute("aria-hidden", "false")

    },
    closeHelpDialog() {
      document.getElementById('help-dialog').setAttribute("aria-hidden", "true")
      AJS.$('#dark-block').css('display', 'none')
      AJS.$('#help-dialog').css('z-index', '0')
    },
    openDocumentation() {
      window.open(this.$documentationLink.value, '_blank').focus();
    },
    openSupport() {
      window.open(this.$supportLink.value, '_blank').focus()
    },
    showOnboarding() {
      document.getElementById('help-dialog').setAttribute("aria-hidden", "true")
      AJS.$('#dark-block').css('display', 'none')
      this.$emit('onbordingemit', true)
      AJS.dialog2('#onboarding').show()
    }
  }
}
</script>
<template>
  <div>
    <section id="help-dialog" class="aui-dialog2 aui-dialog2-medium aui-layer" role="dialog" aria-hidden="true">
      <header class="aui-dialog2-header" style="justify-content: space-between; background: #01334d; border: 0">
        <h2 class="aui-dialog2-header-main" style="max-width: none; color: white">DOIT-BI help</h2>
        <a class="aui-dialog2-header-close" style="color: white" @click="closeHelpDialog">
          <span class="aui-icon aui-icon-small aui-iconfont-close-dialog">Close</span>
        </a>
      </header>
      <div class="aui-dialog2-content" style="text-align: center;padding-right: 280px; border-radius: 10px!important;">
        <div class="help-link" @click="showOnboarding">
          <div class="icon-wrapper" style="background: #6554c0;">
            <i class="icon fas fa-lightbulb"></i>
          </div>
          <div class="text">Plugin tutorial</div>
        </div>
        <div class="help-link" @click="openDocumentation">
          <div class="icon-wrapper">
            <i class="icon fas fa-book-open"></i>
          </div>
          <div class="text">Documentation</div>
          <i class="icon fas fa-external-link-alt" style=" line-height: 1.9; margin-left: 10px; color: #797979; "></i>
        </div>
        <div class="help-link" @click="openSupport">
          <div class="icon-wrapper" style="background: #d41a22;">
            <i class="icon fas fa-comments"></i>
          </div>
          <div class="text">Contact our support</div>
          <i class="icon fas fa-external-link-alt" style=" line-height: 1.9; margin-left: 10px; color: #797979; "></i>
        </div>
      </div>
    </section>
    <div class="sidebar"
         :style="collapsed?{'width': '50px', 'top':($isOnboarding.value?'62px':'0')}:{'width':($pluginKey.value==='com.doitbi.bestbi.cloud'?190:185)+'px', 'top':($isOnboarding.value?'62px':'0')}">
      <div id="dark-block" style="
    width: -webkit-fill-available;
    width: -moz-available;
    height: -webkit-fill-available;
    height: -moz-available;
    margin-left: -5px;
    margin-top: -5px;
    position: fixed;
    display: none;
    z-index: 5;
    background: rgba(0,0,0,.4);
"></div>
      <span v-if="collapsed" style="margin-bottom: 10px">
       <img src="../../assets/doitbi_logo_white.svg">
      </span>
      <span v-else style="width: 50%; margin-left: auto; margin-right: auto"><img
          src="../../assets/doitbi_logo_white.svg"></span>
      <SidebarLink to="data-sources" providertype="POWERBI" icon="fas fa-home" :collapsed="collapsed"
                   v-if="$pluginKey.value==='com.doitbi.bestbi.cloud-powerbi'">
        Data sources
      </SidebarLink>
      <SidebarLink to="data-sources" providertype="POWERBI" icon="fas fa-home" :collapsed="collapsed"
                   v-if="$pluginKey.value==='com.doitbi.bestbi.cloud-powerbi-test'">
        Data sources
      </SidebarLink>
      <SidebarLink to="data-sources" providertype="TABLEAU" icon="fas fa-home" :collapsed="collapsed"
                   v-if="$pluginKey.value==='com.doitbi.bestbi.cloud-tableau'">
        Data sources
      </SidebarLink>
      <SidebarLink to="data-sources" providertype="GDS" icon="fas fa-home" :collapsed="collapsed"
                   v-if="$pluginKey.value==='com.doitbi.bestbi.cloud-gds'">
        Data sources
      </SidebarLink>
      <SidebarLink to="data-sources" providertype="QLIK" icon="fas fa-home" :collapsed="collapsed"
                   v-if="$pluginKey.value==='com.doitbi.bestbi.cloud-qlik'">
        Data sources
      </SidebarLink>
      <SidebarLink to="data-sources" providertype="EXCEL" icon="fas fa-home" :collapsed="collapsed"
                   v-if="$pluginKey.value==='com.doitbi.bestbi.cloud-excel'">
        Data sources
      </SidebarLink>
      <SidebarLink to="data-sources" providertype="POWERBI" icon="fas fa-home" :collapsed="collapsed"
                   v-if="$pluginKey.value==='com.doitbi.bestbi.cloud'">
        Power BI Data Sources
      </SidebarLink>
      <SidebarLink to="data-sources-tableau" providertype="TABLEAU" icon="fas fa-home" :collapsed="collapsed"
                   v-if="$pluginKey.value==='com.doitbi.bestbi.cloud'">
        Tableau Data Sources
      </SidebarLink>

      <SidebarLink to="sample-reports" icon="fas fa-chart-bar" :collapsed="collapsed"
                   v-if="$pluginKey.value==='com.doitbi.bestbi.cloud' || $pluginKey.value==='com.doitbi.bestbi.cloud-powerbi'">
        Sample Reports
      </SidebarLink>

      <SidebarLink to="export-settings" icon="fas fa-wrench" :collapsed="collapsed" v-if="$isAdmin.value">Administration
      </SidebarLink>
      <a @click="openHelpDialog"
         :style="onboarding()?{'pointer-events':'none'}:{},
         collapsed?{'justify-content':'center','text-decoration':'none','background':'white','width':'30px','margin-right':'auto','margin-left':'auto','margin-bottom':'10px','margin-top':'10px'}:onboarding()?{'pointer-events':'none'}:{},{'justify-content':'center','text-decoration':'none','background':'white', 'margin-top':'10px'}"
         aria-controls="support-link-onboarding"
         id="support-link" target="_blank" class="link with-onboarding">
        <i style="font-size: 20px; color: #d61921" class="icon fas fa-question"
           aria-controls="support"/>
        <span v-if="!collapsed" style="margin-left: 10px; color: black; font-weight: 700;">
        DOIT-BI HELP
      </span>
        <aui-inline-dialog class="sidebar-inline" id="support">
          DOIT-BI Help
        </aui-inline-dialog>
      </a>

      <span
          class="collapse-icon"
          :class="{ 'rotate-180': collapsed }"
          :style="{'margin-left': collapsed?'22px':($pluginKey.value==='com.doitbi.bestbi.cloud'?'152px':'122px') }"
          @click="toggleSidebar"
      >
      <i class="fas fa-angle-double-left"/>
    </span>
    </div>
  </div>
</template>

<style>
.link {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  user-select: none;
  margin: 0.1em 0;
  padding: 0.4em;
  border-radius: 0.25em;
  height: 1.5em;
  color: white;
  text-decoration: none;
}

.link:hover {
  background-color: #003381;
}

.link.active {
  background-color: #0052cc;
}

.link .icon {
  flex-shrink: 0;
}

:root {
  --sidebar-bg-color: #2f855a;
  --sidebar-item-hover: #38a169;
  --sidebar-item-active: #276749;
}
</style>

<style scoped>
.help-link {
  display: flex;
  padding: 10px;
  cursor: pointer;
}

.help-link:hover {
  background: #efefef;
  border-radius: 10px;
  padding: 10px;
}

.help-link .icon-wrapper {
  background: #01334d;
  padding: 5px;
  border-radius: 5px;
  width: 30px
}

.help-link .icon-wrapper .icon {
  font-size: 25px;
  color: white
}

.help-link .text {
  margin-left: 10px;
  line-height: 1.6;
  font-size: 21px;
}

.sidebar {
  color: white;
  background-color: #01334d !important;
  float: left;
  position: fixed;
  z-index: 10;
  top: 0;
  left: 0;
  bottom: 0;
  padding: 5px;
  transition: 0.3s ease;
  display: flex;
  flex-direction: column;
}

.sidebar h1 {
  height: 2.5em;
}

.collapse-icon {
  position: absolute;
  bottom: 100px;
  padding: 0.75em;
  color: white;
  transition: 0.2s linear;
  border: 1px solid black;
  border-radius: 5px;
  background: #d61921;
  padding-right: 12px;
}

.rotate-180 {
  transform: rotate(180deg);
  transition: 0.2s linear;
  margin-left: 0;
}
</style>
