export * from './aui.side-effects';
export { log, error, warn } from './behaviours/aui.behaviour.logger';
import { I18n } from './behaviours/aui.behaviour.i18n';
import './aui.batch.page';
import './aui.batch.patterns';
import './aui.batch.iconography';
export * from './aui.batch.components';
// Optional features
import './aui.component.form-notification';
import './aui.component.form-validation';
import { Header } from './aui.component.async-header';
import { DatePicker } from './aui.component.form.date-select';
import { RestfulTable } from './aui.component.restful-table';
import { sidebar } from './aui.component.sidebar';
import { whenIType } from './behaviours/aui.behaviour.keyboard-shortcuts';

export {
    I18n,
    Header,
    DatePicker,
    RestfulTable,
    sidebar,
    whenIType,
};

export const {
    FocusManager,
    InlineDialog2,
    LayerManager,
    ProgressiveDataSet,
    clone,
    debounce,
    debounceImmediate,
    dim,
    escapeHtml,
    formValidation,
    id,
    isClipped,
    keyCode,
    layer,
    messages,
    populateParameters,
    responsiveheader,
    select,
    tablessortable,
    tabs,
    undim,
    _addID
} = window.AJS;

// Fix AUI + WRM compatibility in a hacky way.
export const { contextPath } = window.AJS;
