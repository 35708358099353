import Vue from 'vue'
import Router from 'vue-router'
import {createMemoryHistory} from 'vue-router'
import Start from '../Start'

Vue.use(Router)

const router = new Router({
    mode: 'history',
    linkExactActiveClass: 'active',
    routes: [
        {
            path: '/getting-started',
            name: 'getting-started',
            component: () => import('../components/getting-started/GettingStarted.vue'),
            props: true
        },
        {
            path: '/data-sources',
            name: 'data-sources',
            component: () => import('../components/data-sources/DataSources.vue'),
            props: true
        },
        {
            path: '/emptyDataSourcesPage',
            name: 'emptyDataSourcesPage',
            component: () => import('../components/onboarding/EmptyDataSourcesPage.vue'),
            props: true
        },
        {
            path: '/administrations',
            name: 'administrations',
            component: () => import('../components/onboarding/administrations/Administrations.vue'),
            props: true
        },
        {
            path: '/data-sources-onboarding',
            name: 'data-sources-onboarding',
            component: () => import('../components/onboarding/DataSourcesPage.vue'),
            props: true
        },
        {
            path: '/sample-reports-onboarding',
            name: 'sample-reports-onboarding',
            component: () => import('../components/onboarding/SampleReports.vue'),
            props: true
        },
        {
            path: '/apps',
            name: 'apps',
            component: () => import('../components/onboarding/administrations/Apps.vue'),
            props: true
        },
        {
            path: '/secure-embed-dashboard',
            name: 'secure-embed-dashboard',
            component: () => import('@/components/dashboards/SecureEmbedDashboard.vue'),
            props: true
        },
        {
            path: '/projects-dashboard',
            name: 'projects-dashboard',
            component: () => import('../components/projects-dashboard/ProjectsDashboard.vue'),
            props: true
        },
        {
            path: '/issues-dashboard',
            name: 'issues-dashboard',
            component: () => import('../components/issues-dashboard/IssuesDashboard.vue'),
            props: true
        },
        {
            path: '/no-cookie-safari',
            name: 'no-cookie-safari',
            component: () => import('../components/no-cookie/safari/SafariNoCookie'),
            props: true
        },
        {
            path: '/create-connector-onboarding',
            name: 'create-connector-onboarding',
            component: () => import('../components/onboarding/CreateConnectorPage'),
            props: true
        },
        {
            path: '/data-sources-tableau',
            name: 'data-sources-tableau',
            component: () => import('../components/data-sources/DataSources.vue'),
            props: true
        },
        {
            path: '/audit',
            name: 'audit',
            component: () => import('../components/audit-conn/AuditConn.vue'),
            props: true
        },
        {
            path: '/export-settings',
            name: 'export-settings',
            component: () => import('../components/export-settings/ExportSettings.vue'),
            props: true
        },
        {
            path: '/jira-settings',
            name: 'jira-settings',
            component: () => import('../components/jira-settings/JiraSettings.vue')
        },
        {
            path: '/create-data-source',
            name: 'create',
            component: () => import('../components/create-data-source/CreateDataSource.vue'),
            props: true
        },
        {
            path: '/sample-reports',
            name: 'sample-reports',
            component: () => import('../components/sample-reports/SampleReports.vue'),
            props: true
        },
        {
            path: '/forbidden',
            name: 'forbidden',
            component: () => import('../components/forbidden/ForbiddenComponent.vue')
        },
        {
            path: '/license',
            name: 'license',
            component: () => import('../components/licence-block/LicenceBlock.vue')
        },
        {path: '*', component: Start}
    ]
})

router.beforeEach((to, from, next) => {
    next()
})

export default router
