const NAMESPACE = 'AJS';
const I18N_OBJECT_NAME = 'I18n';

function initNamespace() {
    if (typeof window[NAMESPACE] !== 'object') {
        window[NAMESPACE] = {};
    }
}

export default function globalize (name, value) {
    initNamespace();

    return window[NAMESPACE][name] = value;
}

export function putOnI18nIfAbsent (name, value) {
    initNamespace();

    if (typeof window[NAMESPACE][I18N_OBJECT_NAME] !== 'object') {
        window[NAMESPACE][I18N_OBJECT_NAME] = {};
    }

    if (typeof window[NAMESPACE][I18N_OBJECT_NAME][name] === 'undefined') {
        window[NAMESPACE][I18N_OBJECT_NAME][name] = value;
    }
}
