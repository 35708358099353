import { render, staticRenderFns } from "./VaNotification.vue?vue&type=template&id=17fb905a&scoped=true&"
import script from "./VaNotification.vue?vue&type=script&lang=js&"
export * from "./VaNotification.vue?vue&type=script&lang=js&"
import style0 from "../style/_reset.scss?vue&type=style&index=0&id=17fb905a&lang=scss&scoped=true&"
import style1 from "./VaNotification.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "17fb905a",
  null
  
)

export default component.exports