import { render, staticRenderFns } from "./VaMinibar.vue?vue&type=template&id=29f4670c&scoped=true&"
import script from "./VaMinibar.vue?vue&type=script&lang=js&"
export * from "./VaMinibar.vue?vue&type=script&lang=js&"
import style0 from "../style/_reset.scss?vue&type=style&index=0&id=29f4670c&lang=scss&scoped=true&"
import style1 from "./VaMinibar.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29f4670c",
  null
  
)

export default component.exports