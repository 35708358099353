<template>
  <div id="app" style="overflow: auto">
    <header v-if="onboarding" class="aui-dialog2-header"
            style="position: fixed; display: flex; background: white; z-index: 100; ">
      <h2 class="aui-dialog2-header-main" v-if="$providerType.value === 'POWERBI'">Power BI Connector for Jira
        onboarding</h2>
      <h2 class="aui-dialog2-header-main" v-if="$providerType.value === 'TABLEAU'">Tableau Connector for Jira
        onboarding</h2>
      <h2 class="aui-dialog2-header-main" v-if="$providerType.value === 'QLIK'">Qlik Sense & Qlik View Connector for
        Jira onboarding</h2>
      <h2 class="aui-dialog2-header-main" v-if="$providerType.value === 'GDS'">Looker Studio Connector for Jira
        onboarding</h2>
      <a class="aui-dialog2-header-close" @click="closeGlobalOnboarding"><span
          class="aui-icon aui-icon-small aui-iconfont-close-dialog">Close</span></a></header>
    <Sidebar v-on:sidebaremit="updateSidebar" v-on:openhelpglobal="openHelp"
             v-if="showSidebar && !$isDashboard.value"></Sidebar>
    <div
        :style="onboarding?{'margin-left': sidebarWidth + 'px', 'margin-top':'67px'}:($isDashboard.value?{}:{'margin-left': sidebarWidth + 'px'})">
      <router-view :openHelpDialog="openHelpWindow"/>

      <section id="onboarding" class="aui-dialog2 aui-dialog2-large aui-layer" role="dialog"
               aria-hidden="true" style="border-radius: 10px; box-shadow: 0px 5px 20px black!important">
        <header class="aui-dialog2-header"
                style="justify-content: end; border: 0; background-color: white!important; background: white!important;">
          <a class="aui-dialog2-header-close">
            <span class="aui-icon aui-icon-small aui-iconfont-close-dialog"
                  style="z-index: 1000; color: black">Close</span>
          </a>
        </header>
        <div class="aui-dialog2-content" style="padding:0 30px">
          <div style="
    width: 400px;
    height: 400px;
    border-bottom-left-radius: 400px;
    border-top-right-radius: 10px;
    background: #B3B3FF;
    position: absolute;
    display: flex;
    margin-top: -62px;
    right: 0;
"></div>

          <div style="width: 50%; float: right; position: relative"><img
              src="./assets/onboarding/onboarding-tutorial.svg"/></div>
          <h2 v-if="$providerType.value==='POWERBI'">Welcome to the Power BI Connector</h2>
          <h2 v-if="$providerType.value==='TABLEAU'">Welcome to the Tableau Connector</h2>
          <h2 v-if="$providerType.value==='QLIK'">Welcome to the Qlik Sense & Qlik View Connector</h2>
          <h2 v-if="$providerType.value==='GDS'">Welcome to the Looker Studio Connector</h2>
          <div style="font-size: 15px">We have prepared a short tutorial to help you better understand our
            application.<br><br>
            You will learn how to:<br><br>
            <div style="display: flex"><img src="./assets/onboarding/onboarding-create.svg"
                                            style="width: 20px; margin-right: 5px; margin-bottom: 10px"/> Create data
              sources
            </div>
            <div style="display: flex" v-if="$providerType.value==='POWERBI'"><img
                src="./assets/onboarding/onboarding-export.svg"
                style="width: 20px; margin-right: 5px; margin-bottom: 10px"/> Export data to Power BI
            </div>
            <div style="display: flex" v-if="$providerType.value==='TABLEAU'"><img
                src="./assets/onboarding/onboarding-export.svg"
                style="width: 20px; margin-right: 5px; margin-bottom: 10px"/> Export data to Tableau
            </div>
            <div style="display: flex" v-if="$providerType.value==='QLIK'"><img
                src="./assets/onboarding/onboarding-export.svg"
                style="width: 20px; margin-right: 5px; margin-bottom: 10px"/> Export data to Qlik Sense & Qlik View
            </div>
            <div style="display: flex" v-if="$providerType.value==='GDS'"><img
                src="./assets/onboarding/onboarding-export.svg"
                style="width: 20px; margin-right: 5px; margin-bottom: 10px"/> Export data to Looker Studio
            </div>
            <div style="display: flex"><img src="./assets/onboarding/onboarding-diagram.svg"
                                            style="width: 20px; margin-right: 5px"/> Build interactive dashboards
            </div>
            <br>
            Start improving your business strategy <br> with our application right now!
          </div>
        </div>
        <div style="
    width: 150px;
    height: 150px;
    border-top-right-radius: 150px;
    background: #FF2C9C;
    margin-top: -70px;
    border-bottom-left-radius: 10px;
"></div>
        <div style="margin-top: -62px; margin-left:150px; margin-bottom: 20px; text-align: center">
          <button class="aui-button aui-button-primary" @click="startOnboarding"
                  style="background-color: #5243aa!important; padding: 20px; line-height: 0; border-radius: 20px!important;">
            Let's DO IT together
          </button>
          <button class="aui-button aui-button-primary" @click="skipOnboarding"
                  style="color: black!important; margin-left: 50px; padding: 20px; line-height: 0; border-radius: 20px!important; background-color: #DFDFDF!important; ">
            Explore by myself
          </button>
        </div>
      </section>
      <aui-inline-dialog v-if="$isOnboarding.value" class="onboarding" id="name-description-block-onboarding"
                         alignment="right middle"
                         persistent>
        <div class="inline-dialog-header">
          <h3>Data Source details</h3>
          <span class="aui-icon aui-icon-small aui-iconfont-cross close-button"
                @click="closeGlobalOnboarding">Close</span>
        </div>
        <p>
          Provide a name for your Data Source and, if desired, add a brief description.<br>
          <strong>Note: </strong> The name field is required.
        </p>
        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">{{ $isAdmin.value ? 9 : 2 }}/{{ $onboardingCount.value }}</div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="backButtonOnDetailsOnboarding">Back</button>
            <button class="aui-button aui-button-primary" @click="nextButtonOnDetailsOnboarding">Next</button>
          </div>
        </div>
      </aui-inline-dialog>
      <aui-inline-dialog v-if="$isOnboarding.value" class="onboarding" id="create-connector-button-onboarding"
                         alignment="right middle" persistent>
        <div class="inline-dialog-header">
          <h3>Create a Data Source</h3>
          <span class="aui-icon aui-icon-small aui-iconfont-cross close-button"
                @click="closeGlobalOnboarding">Close</span>
        </div>
        <p>Create your first data source <br>
          by clicking on the <strong>Create a data source</strong> button.

        </p>
        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">{{ $isAdmin.value ? 8 : 1 }}/{{ $onboardingCount.value }}</div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="backToAdminPage" v-if="$isAdmin.value">Back</button>
            <button class="aui-button aui-button-primary" @click="openCreateConnector">Next</button>
          </div>
        </div>
      </aui-inline-dialog>
      <aui-inline-dialog v-if="$isOnboarding.value" class="onboarding" id="tab-section-block-onboarding"
                         alignment="bottom center"
                         persistent>
        <div class="inline-dialog-header">
          <h3>Plugins</h3>
          <span class="aui-icon aui-icon-small aui-iconfont-cross close-button"
                @click="closeGlobalOnboarding">Close</span>
        </div>
        <p>
          Click on any of the tabs below and you will get a set of tables and fields related to this plugin.
          <br>
          Here you can find both the main Jira tabs and the tabs of the plugins that we have integrated.<br>
          For example, by selecting the <strong>Jira Platform</strong> tab, you will get a list of the main
          <strong>Jira</strong>
          tables and
          fields.
        </p>
        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">{{ $isAdmin.value ? 10 : 3 }}/{{ $onboardingCount.value }}</div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="backButtonOnTabOnboarding">Back</button>
            <button class="aui-button aui-button-primary" @click="nextButtonOnTabOnboarding">Next</button>
          </div>
        </div>
      </aui-inline-dialog>


      <aui-inline-dialog v-if="$isOnboarding.value" class="onboarding" id="worklog-table-onboarding"
                         alignment="top center" persistent>
        <div class="inline-dialog-header">
          <h3>Tables and Fields Selection</h3>
          <span class="aui-icon aui-icon-small aui-iconfont-cross close-button"
                @click="closeGlobalOnboarding">Close</span>
        </div>
        <p> Select certain fields in the table or select it completely by clicking on the checkbox next to the table.
        </p>
        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">{{ $isAdmin.value ? 11 : 4 }}/{{ $onboardingCount.value }}</div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="backButtonOnWorklogTableOnboarding">Back
            </button>
            <button class="aui-button aui-button-primary" @click="nextButtonOnWorklogTableOnboarding">Next
            </button>
          </div>
        </div>
      </aui-inline-dialog>

      <aui-inline-dialog v-if="$isOnboarding.value" class="onboarding" id="save-preview-section-onboarding"
                         alignment="bottom right"
                         persistent>
        <div class="inline-dialog-header">
          <h3>Preview & Save data source</h3>
          <span class="aui-icon aui-icon-small aui-iconfont-cross close-button"
                @click="closeGlobalOnboarding">Close</span>
        </div>
        <p>
          Before saving the data source, you can view a list of your tables and the relationships<br> between them by
          clicking
          <strong>Preview</strong> data source.
        </p>
        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">{{ $isAdmin.value ? 14 : 7 }}/{{ $onboardingCount.value }}</div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="backButtonOnSavePreviewOnboarding">Back</button>
            <button class="aui-button aui-button-primary" @click="nextButtonOnSavePreviewOnboarding">Next</button>
          </div>
        </div>
      </aui-inline-dialog>

      <aui-inline-dialog v-if="$isOnboarding.value" class="onboarding" id="filter-section-onboarding"
                         alignment="right middle"
                         persistent>
        <div class="inline-dialog-header">
          <h3>Filters Section</h3>
          <span class="aui-icon aui-icon-small aui-iconfont-cross close-button"
                @click="closeGlobalOnboarding">Close</span>
        </div>
        <p>Filter your data source data by setting the parameters of one of the filters and <br>selecting the time
          period.
        </p>
        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">{{ $isAdmin.value ? 12 : 5 }}/{{ $onboardingCount.value }}</div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="backButtonOnFiltersOnboarding">Back</button>
            <button class="aui-button aui-button-primary" @click="nextButtonOnFiltersOnboarding">Next</button>
          </div>
        </div>
      </aui-inline-dialog>

      <aui-inline-dialog v-if="$isOnboarding.value" class="onboarding" id="schedule-section-onboarding"
                         alignment="right middle"
                         persistent>
        <div class="inline-dialog-header">
          <h3>Scheduled refresh</h3>
          <span class="aui-icon aui-icon-small aui-iconfont-cross close-button"
                @click="closeGlobalOnboarding">Close</span>
        </div>
        <p>
          Set up auto updates of your data and save your time.<br>
          <strong>Note:</strong> This section is only available when the data preparation function is enabled.<br> <a
            style="color: white; text-decoration: underline; outline: none" :href="$dataPreparationLink.value"
            target="_blank">Learn more </a>
        </p>
        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">{{ $isAdmin.value ? 13 : 6 }}/{{ $onboardingCount.value }}</div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="backButtonOnScheduleOnboarding">Back
            </button>
            <button class="aui-button aui-button-primary" @click="nextButtonOnScheduleOnboarding">Next
            </button>
          </div>
        </div>
      </aui-inline-dialog>

      <aui-inline-dialog v-if="$isOnboarding.value" class="onboarding" id="prepare-data-switch-onboarding"
                         alignment="left middle" persistent>
        <div class="inline-dialog-header">
          <h3>Data preparation</h3>
          <span class="aui-icon aui-icon-small aui-iconfont-cross close-button"
                @click="closeGlobalOnboarding">Close</span>
        </div>
        <p>
          To avoid the load on the Jira and also speed up the export when exporting a large amount of data,
          <br> you can use the data preparation functionality.<br><br>
          Do you want to know more about it?

          <a :href="$dataPreparationLink.value" target="_blank"
             style="color: white; text-decoration: underline; outline: none">
            Learn more</a>
        </p>
        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">{{ $isAdmin.value ? 17 : 10 }}/{{ $onboardingCount.value }}</div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="backButtonOnPrepareOnboarding">Back</button>
            <button class="aui-button aui-button-primary" @click="nextButtonOnPrepareOnboarding">Next</button>
          </div>
        </div>
      </aui-inline-dialog>

      <aui-inline-dialog v-if="$isOnboarding.value" class="onboarding" id="actions-dropdown999-onboarding"
                         alignment="left middle" persistent>
        <div class="inline-dialog-header">
          <h3>Actions</h3>
          <span class="aui-icon aui-icon-small aui-iconfont-cross close-button"
                @click="closeGlobalOnboarding">Close</span>
        </div>
        <p>This menu provides access to additional functions.<br> <strong>Copy</strong> , <strong>Edit</strong>,
          <strong>Clone</strong>, <strong>Share</strong> your data sources
          in a few clicks and much more.
        </p>
        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">{{ $isAdmin.value ? 16 : 9 }}/{{ $onboardingCount.value }}</div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="backButtonOnDropdownOnboarding">Back
            </button>
            <button class="aui-button aui-button-primary" @click="nextButtonOnDropdownOnboarding">Next
            </button>
          </div>
        </div>
      </aui-inline-dialog>

      <aui-inline-dialog v-if="$isOnboarding.value" class="onboarding" id="data-sources-block-onboarding"
                         alignment="bottom center" persistent>
        <div class="inline-dialog-header">
          <h3>Data sources</h3>
          <span class="aui-icon aui-icon-small aui-iconfont-cross close-button"
                @click="closeGlobalOnboarding">Close</span>
        </div>
        <p>
          This is the All Data Sources page, which will show you all the data sources you have created <br>
          and the data sources other users have shared with you.
          <br><br>
          <strong>Note: </strong> the admin has access to data sources of all users.

        </p>
        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">{{ $isAdmin.value ? 15 : 8 }}/{{ $onboardingCount.value }}</div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="backButtonOnDataSourcesBlockOnboarding">Back</button>
            <button class="aui-button aui-button-primary" @click="nextButtonOnDataSourcesBlockOnboarding">Next</button>
          </div>
        </div>
      </aui-inline-dialog>

      <aui-inline-dialog v-if="$isOnboarding.value" class="onboarding" id="sample-reports-link-onboarding"
                         alignment="left middle" persistent>
        <div class="inline-dialog-header">
          <h3>Sample reports</h3>
          <span class="aui-icon aui-icon-small aui-iconfont-cross close-button"
                @click="closeGlobalOnboarding">Close</span>
        </div>
        <p>Here is a set of ready-made template solutions, ready-made analytics.<br>
          Just download the report and open it in <strong>Power BI</strong>.<br>
          Your Jira data will be automatically exported to a ready-made template.
        </p>
        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">{{ $isAdmin.value ? 18 : 11 }}/{{ $onboardingCount.value }}</div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="backButtonOnSampleReportsOnboarding">Back</button>
            <button class="aui-button aui-button-primary" @click="nextButtonOnSampleReportsOnboarding">Next</button>
          </div>
        </div>
      </aui-inline-dialog>

      <aui-inline-dialog v-if="$isOnboarding.value" class="onboarding" id="get-report-onboarding" persistent
                         alignment="bottom center">
        <div class="inline-dialog-header"><h3>Get report</h3> <span
            class="aui-icon aui-icon-small aui-iconfont-cross close-button"
            @click="closeGlobalOnboarding">Close</span>
        </div>
        <p>Get Report - allows you to customize your report data source <br>according to your needs before export
          to Power BI.</p>
        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">{{ $isAdmin.value ? 20 : 13 }}/{{ $onboardingCount.value }}</div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="backButtonOnGetReportOnboarding">Back</button>
            <button class="aui-button aui-button-primary" @click="nextButtonOnGetReportOnboarding">Next</button>
          </div>
        </div>
      </aui-inline-dialog>

      <aui-inline-dialog v-if="$isOnboarding.value" class="onboarding" id="get-example-onboarding" persistent
                         alignment="bottom center">
        <div class="inline-dialog-header"><h3>Get example report</h3> <span
            class="aui-icon aui-icon-small aui-iconfont-cross close-button"
            @click="closeGlobalOnboarding">Close</span>
        </div>
        <p>Get Example - download and open sample with prefilled data in Power BI.</p>
        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">{{ $isAdmin.value ? 19 : 12 }}/{{ $onboardingCount.value }}</div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="backButtonOnGetExampleOnboarding">Back</button>
            <button class="aui-button aui-button-primary" @click="nextButtonOnGetExampleOnboarding">Next</button>
          </div>
        </div>
      </aui-inline-dialog>
      <aui-inline-dialog class="onboarding" id="export-settings-link-onboarding" persistent>
        <div class="inline-dialog-header"><h3>Administration</h3> <span
            class="aui-icon aui-icon-small aui-iconfont-cross close-button"
            @click="closeGlobalOnboarding">Close</span>
        </div>
        The <strong>Administration</strong> page is only accessible to <strong>Jira administrators</strong>.<br><br>
        On this page, you can:
        <ul>
          <li>configure the export of plugins and projects;</li>
          <li>set permissions for specific users or groups to access the plugin;</li>
          <li>monitor and manage changes to data sources.;</li>
        </ul>
        <br>
        Let's take a closer look at these features.
        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">1/{{ $onboardingCount.value }}</div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="nextButtonOnAdminOnboarding">Next</button>
          </div>
        </div>
      </aui-inline-dialog>
      <aui-inline-dialog class="onboarding" id="support-link-onboarding" persistent>
        <div class="inline-dialog-header"><h3>DOIT-BI Help</h3> <span
            class="aui-icon aui-icon-small aui-iconfont-cross close-button"
            @click="closeGlobalOnboarding">Close</span>
        </div>
        If you have any questions about the plugin,<br>
        then you can use this button.
        <br>
        <br>
        Here you can:
        <ul>
          <li>go through onboarding from the beginning;</li>
          <li>take advantage of the documentation;</li>
          <li>contact support;</li>
        </ul>

        <div style="display: flex;justify-content: space-between;">
          <div style="margin-top: 25px;">{{
              $isAdmin.value ? ($providerType.value === 'POWERBI' ? 21 : 18) : ($providerType.value === 'POWERBI' ? 14 : 11)
            }}/{{ $onboardingCount.value }}
          </div>
          <div style="float: right; margin-top: 20px">
            <button class="aui-button aui-button-primary" @click="backOnFinish">Back</button>
            <button class="aui-button aui-button-primary" @click="finish">Finish</button>
          </div>
        </div>
      </aui-inline-dialog>
    </div>
  </div>
</template>

<script>
import Sidebar from './components/sidebar/Sidebar'

export default {
  name: 'app',
  components: {Sidebar},

  data() {
    return {
      onboarding: this.$isOnboarding.value,
      openHelpWindow: false,
      loading: true,
      showSidebar: true,
      sidebarWidth: this.$pluginKey.value === 'com.doitbi.bestbi.cloud' ? 190 : 185
    }
  },
  mounted() {
    if (!this.$isDashboard.value) {
      AJS.$('#app').css('height', window.innerHeight)
      AJS.$('#app').css('position', 'fixed')
    }
    if (this.$route.params.pathMatch.includes('getting-started')) {
      this.showSidebar = false
      this.sidebarWidth = 0
    }
    if (this.$route.params.pathMatch.includes('projects-dashboard')) {
      this.showSidebar = false
      this.sidebarWidth = 0
    }
    this.loading = false
  },
  methods: {
    openCreateConnector() {
      AJS.$('#create-connector-button').css('border', '')
      AJS.$('#create-connector-button').css('box-shadow', '')
      AJS.$('#create-connector-button').css('z-index', '')
      var inlineDialog = document.getElementById('create-connector-button-onboarding')
      inlineDialog.removeAttribute('open')
      this.$router.push({name: 'create-connector-onboarding'})
    },
    backToAdminPage() {
      AJS.$('#create-connector-button').css('border', '')
      AJS.$('#create-connector-button').css('box-shadow', '')
      AJS.$('#create-connector-button').css('z-index', '')
      var inlineDialog = document.getElementById('create-connector-button-onboarding')
      inlineDialog.removeAttribute('open')
      this.$router.push({name: 'administrations', params: {isBackFromCreatePage: true}})
    },
    backButtonOnDetailsOnboarding() {
      var inlineDialog = document.getElementById('name-description-block-onboarding')
      inlineDialog.removeAttribute('open')
      AJS.$('#dark-block').css('display', 'none')
      AJS.$('#name-description-block').css('border', '')
      AJS.$('#name-description-block').css('box-shadow', '')
      AJS.$('#name-description-block').css('z-index', '')
      var that = this

      that.$router.push({
        name: 'emptyDataSourcesPage',
        params: {providerType: that.$providerType.value, selectNextOnboardingStage: true}
      })

    },
    nextButtonOnDetailsOnboarding() {
      AJS.$('#name-description-block').css('border', '')
      AJS.$('#name-description-block').css('box-shadow', '')
      AJS.$('#name-description-block').css('z-index', '')

      AJS.$('#tab-section-block').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#tab-section-block').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#tab-section-block').css('z-index', '10')
      AJS.$('#jira-platform').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#jira-platform').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#jira-platform').css('z-index', '10')


      var inlineDialog = document.getElementById('name-description-block-onboarding')
      inlineDialog.removeAttribute('open')

      var inlineDialog = document.getElementById('tab-section-block-onboarding')
      inlineDialog.setAttribute('open', '')
    },

    backButtonOnTabOnboarding() {
      AJS.$('#name-description-block').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#name-description-block').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#name-description-block').css('z-index', '10')

      AJS.$('#tab-section-block').css('border', '')
      AJS.$('#tab-section-block').css('box-shadow', '')
      AJS.$('#tab-section-block').css('z-index', '')

      var inlineDialog = document.getElementById('tab-section-block-onboarding')
      inlineDialog.removeAttribute('open')

      var inlineDialog = document.getElementById('name-description-block-onboarding')
      inlineDialog.setAttribute('open', '')
    },

    nextButtonOnTabOnboarding() {

      AJS.$('#jira-platform').css('border', '')
      AJS.$('#jira-platform').css('box-shadow', '')
      AJS.$('#jira-platform').css('z-index', '')

      AJS.$('#worklog-table').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#worklog-table').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#worklog-table').css('z-index', '10')
      AJS.$('#worklog-fields').css('display', 'block')
      AJS.$('#worklog-fields').css('padding-top', '10px!important')
      AJS.$('#worklog-fields').css('padding-bottom', '10px!important')

      AJS.$('#tab-section-block').css('border', '')
      AJS.$('#tab-section-block').css('box-shadow', '')
      AJS.$('#tab-section-block').css('z-index', '')

      var inlineDialog = document.getElementById('worklog-table-onboarding')
      inlineDialog.setAttribute('open', '')

      var inlineDialog = document.getElementById('tab-section-block-onboarding')
      inlineDialog.removeAttribute('open')

      AJS.$('html, body').animate({
        scrollTop: document.getElementById('worklog-table').scrollIntoView({block: 'center', inline: 'end'})
      }, 500)
    },

    backButtonOnWorklogTableOnboarding() {
      AJS.$('#jira-platform').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#jira-platform').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#jira-platform').css('z-index', '10')

      AJS.$('#tab-section-block').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#tab-section-block').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#tab-section-block').css('z-index', '10')
      AJS.$('#jira-platform').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#jira-platform').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#jira-platform').css('z-index', '10')

      AJS.$('#worklog-table').css('border', '')
      AJS.$('#worklog-table').css('box-shadow', '')
      AJS.$('#worklog-table').css('z-index', '')
      AJS.$('#worklog-table').css('padding', '')
      AJS.$('#worklog-fields').css('display', 'none')
      var inlineDialog = document.getElementById('worklog-table-onboarding')
      inlineDialog.removeAttribute('open')

      AJS.$('html, body').animate({
        scrollTop: document.getElementById('jira-platform').scrollIntoView({block: 'end', inline: 'end'})
      }, 500)


      var inlineDialog = document.getElementById('tab-section-block-onboarding')
      inlineDialog.setAttribute('open', '')
    },
    nextButtonOnWorklogTableOnboarding() {
      AJS.$('#worklog-table').css('border', '')
      AJS.$('#worklog-table').css('box-shadow', '')
      AJS.$('#worklog-table').css('z-index', '')
      AJS.$('#worklog-table').css('padding', '')
      AJS.$('#worklog-fields').css('display', 'none')

      AJS.$('#filter-section').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#filter-section').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#filter-section').css('z-index', '10')

      var inlineDialog = document.getElementById('worklog-table-onboarding')
      inlineDialog.removeAttribute('open')

      var inlineDialog = document.getElementById('filter-section-onboarding')
      inlineDialog.setAttribute('open', '')
    },
    backButtonOnFiltersOnboarding() {
      AJS.$('#worklog-table').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#worklog-table').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#worklog-table').css('z-index', '10')
      AJS.$('#worklog-fields').css('display', 'block')
      AJS.$('#worklog-fields').css('padding-top', '10px!important')
      AJS.$('#worklog-fields').css('padding-bottom', '10px!important')

      AJS.$('#filter-section').css('border', '')
      AJS.$('#filter-section').css('box-shadow', '')
      AJS.$('#filter-section').css('z-index', '')

      var inlineDialog = document.getElementById('worklog-table-onboarding')
      inlineDialog.setAttribute('open', '')

      var inlineDialog = document.getElementById('filter-section-onboarding')
      inlineDialog.removeAttribute('open')
    },
    nextButtonOnFiltersOnboarding() {
      AJS.$('#filter-section').css('border', '')
      AJS.$('#filter-section').css('box-shadow', '')
      AJS.$('#filter-section').css('z-index', '')

      AJS.$('#schedule-section').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#schedule-section').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#schedule-section').css('z-index', '10')

      var inlineDialog = document.getElementById('filter-section-onboarding')
      inlineDialog.removeAttribute('open')

      var inlineDialog = document.getElementById('schedule-section-onboarding')
      inlineDialog.setAttribute('open', '')

      AJS.$('html, body').animate({
        scrollTop: document.getElementById('schedule-section').scrollIntoView({block: 'center', inline: 'end'})
      }, 500)
    },
    nextButtonOnScheduleOnboarding() {
      AJS.$('#schedule-section').css('border', '')
      AJS.$('#schedule-section').css('box-shadow', '')
      AJS.$('#schedule-section').css('z-index', '')

      AJS.$('#save-preview-section').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#save-preview-section').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#save-preview-section').css('z-index', '10')
      AJS.$('#save-preview-section').css('padding', '2px')
      AJS.$('#top-menu').css('z-index', '100')

      var inlineDialog = document.getElementById('save-preview-section-onboarding')
      inlineDialog.setAttribute('open', '')

      var inlineDialog = document.getElementById('schedule-section-onboarding')
      inlineDialog.removeAttribute('open')

      AJS.$('html, body').animate({
        scrollTop: document.getElementById('save-preview-section').scrollIntoView({block: 'start', inline: 'end'})
      }, 500)

    },
    backButtonOnScheduleOnboarding() {
      AJS.$('#schedule-section').css('border', '')
      AJS.$('#schedule-section').css('box-shadow', '')
      AJS.$('#schedule-section').css('z-index', '')

      AJS.$('#filter-section').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#filter-section').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#filter-section').css('z-index', '10')

      var inlineDialog = document.getElementById('filter-section-onboarding')
      inlineDialog.setAttribute('open', '')

      var inlineDialog = document.getElementById('schedule-section-onboarding')
      inlineDialog.removeAttribute('open')

      AJS.$('html, body').animate({
        scrollTop: document.getElementById('filter-section').scrollIntoView({block: 'center', inline: 'end'})
      }, 500)
    },
    backButtonOnSavePreviewOnboarding() {
      AJS.$('#save-preview-section').css('border', '')
      AJS.$('#save-preview-section').css('box-shadow', '')
      AJS.$('#save-preview-section').css('z-index', '')
      AJS.$('#save-preview-section').css('padding', '0px')

      AJS.$('#schedule-section').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#schedule-section').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#schedule-section').css('z-index', '10')

      AJS.$('#top-menu').css('z-index', '')

      var inlineDialog = document.getElementById('save-preview-section-onboarding')
      inlineDialog.removeAttribute('open')

      var inlineDialog = document.getElementById('schedule-section-onboarding')
      inlineDialog.setAttribute('open', '')

      AJS.$('html, body').animate({
        scrollTop: document.getElementById('schedule-section').scrollIntoView({block: 'center', inline: 'end'})
      }, 500)
    },
    nextButtonOnSavePreviewOnboarding() {
      AJS.$('#save-preview-section').css('border', '')
      AJS.$('#save-preview-section').css('box-shadow', '')
      AJS.$('#save-preview-section').css('z-index', '')
      AJS.$('#save-preview-section').css('padding', '0px')
      var inlineDialog = document.getElementById('save-preview-section-onboarding')
      inlineDialog.removeAttribute('open')
      this.$router.push({name: 'data-sources-onboarding'})
    },
    backButtonOnDataSourcesBlockOnboarding() {
      AJS.$('#data-sources-block').css('border', '')
      AJS.$('#data-sources-block').css('box-shadow', '')
      AJS.$('#data-sources-block').css('z-index', '')
      var inlineDialog = document.getElementById('data-sources-block-onboarding')
      inlineDialog.removeAttribute('open')
      this.$router.push({name: 'create-connector-onboarding', params: {backFromDataSourcesPage: true}})
    },
    nextButtonOnDataSourcesBlockOnboarding() {
      AJS.$('#actions-dropdown999').attr('aria-hidden', 'false')
      AJS.$('#actions-dropdown999').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#actions-dropdown999').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#actions-dropdown999').css('z-index', '10')
      AJS.$('#actions-dropdown-id').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#actions-dropdown-id').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#actions-dropdown999').css('margin-top', '35px')

      var inlineDialog = document.getElementById('data-sources-block-onboarding')
      inlineDialog.removeAttribute('open')

      var inlineDialog = document.getElementById('actions-dropdown999-onboarding')
      inlineDialog.setAttribute('open', '')
    },
    backButtonOnDropdownOnboarding() {
      AJS.$('#actions-dropdown999').attr('aria-hidden', 'true')
      AJS.$('#actions-dropdown999').css('border', '')
      AJS.$('#actions-dropdown999').css('box-shadow', '')
      AJS.$('#actions-dropdown999').css('z-index', '')
      AJS.$('#actions-dropdown-id').css('border', '')
      AJS.$('#actions-dropdown-id').css('box-shadow', '')
      var inlineDialog = document.getElementById('actions-dropdown999-onboarding')
      inlineDialog.removeAttribute('open')

      var inlineDialog = document.getElementById('data-sources-block-onboarding')
      inlineDialog.setAttribute('open', '')
    },

    nextButtonOnDropdownOnboarding() {
      AJS.$('#actions-dropdown999').attr('aria-hidden', 'true')
      AJS.$('#actions-dropdown999').css('border', '')
      AJS.$('#actions-dropdown999').css('box-shadow', '')
      AJS.$('#actions-dropdown999').css('z-index', '')
      AJS.$('#actions-dropdown-id').css('border', '')
      AJS.$('#actions-dropdown-id').css('box-shadow', '')
      AJS.$('#prepare-data-switch').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#prepare-data-switch').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#prepare-data-switch').css('z-index', '10')
      AJS.$('#prepare-data-switch').css('padding', '2px')

      var inlineDialog = document.getElementById('actions-dropdown999-onboarding')
      inlineDialog.removeAttribute('open')

      var inlineDialog = document.getElementById('prepare-data-switch-onboarding')
      inlineDialog.setAttribute('open', '')
    },
    backButtonOnSampleReportsOnboarding() {
      AJS.$('#sample-reports-link').css('border', '')
      AJS.$('#sample-reports-link').css('box-shadow', '')
      AJS.$('#sample-reports-link').css('z-index', '')
      AJS.$('#prepare-data-switch').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#prepare-data-switch').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#prepare-data-switch').css('z-index', '10')
      AJS.$('#prepare-data-switch').css('padding', '2px')
      AJS.$('#data-sources-link-POWERBI').addClass('active')
      AJS.$('#data-sources-block').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#data-sources-block').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#data-sources-block').css('z-index', '10')

      var inlineDialog = document.getElementById('sample-reports-link-onboarding')
      inlineDialog.removeAttribute('open')

      var inlineDialog = document.getElementById('prepare-data-switch-onboarding')
      inlineDialog.setAttribute('open', '')
    },

    nextButtonOnPrepareOnboarding() {
      AJS.$('#data-sources-link-POWERBI').removeClass('active')
      AJS.$('#prepare-data-switch').attr('aria-hidden', 'true')
      AJS.$('#prepare-data-switch').css('border', '')
      AJS.$('#prepare-data-switch').css('box-shadow', '')
      AJS.$('#prepare-data-switch').css('z-index', '')
      AJS.$('#data-sources-block').css('border', '')
      AJS.$('#data-sources-block').css('box-shadow', '')
      AJS.$('#data-sources-block').css('z-index', '')
      AJS.$('#data-sources-link-POWERBI').css('z-index', '')
      var inlineDialog = document.getElementById('data-sources-block-onboarding')
      inlineDialog.removeAttribute('open')
      var inlineDialog = document.getElementById('prepare-data-switch-onboarding')
      inlineDialog.removeAttribute('open')

      if (this.$providerType.value === 'POWERBI') {
        AJS.$('#sample-reports-link').css('border', '3px solid rgb(120 111 182)')
        AJS.$('#sample-reports-link').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
        AJS.$('#sample-reports-link').css('z-index', '10')
        var inlineDialog = document.getElementById('sample-reports-link-onboarding')
        inlineDialog.setAttribute('open', '')
      } else {
        AJS.$('#support-link').css('border', '3px solid rgb(120 111 182)')
        AJS.$('#support-link').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
        var inlineDialog = document.getElementById('support-link-onboarding')
        inlineDialog.setAttribute('open', '')
      }
    },
    nextButtonOnAdminOnboarding() {
      AJS.$('#create-connector-button').css('border', '')
      AJS.$('#create-connector-button').css('box-shadow', '')
      AJS.$('#create-connector-button').css('z-index', '')
      var inlineDialog = document.getElementById('create-connector-button-onboarding')
      inlineDialog.removeAttribute('open')
      this.closeOnboarding('export-settings-link', 'export-settings-link-onboarding')
      this.$router.push({name: 'administrations'})
    },
    closeOnboarding(element, inlineDialogId, zindex) {
      var inlineDialog = document.getElementById(inlineDialogId)
      inlineDialog.removeAttribute('open')
      this.deleteOnboardingBoarder(element)
      if (zindex) {
        AJS.$('#' + zindex).css('z-index', '')
      }
    },
    deleteOnboardingBoarder(element, zindex) {
      AJS.$('#' + element).css('border', '')
      AJS.$('#' + element).css('box-shadow', '')
      AJS.$('#' + element).css('z-index', '')
      if (zindex) {
        AJS.$('#' + zindex).css('z-index', '')
      }
    },
    backButtonOnPrepareOnboarding() {
      AJS.$('#data-sources-link-POWERBI').removeClass('active')
      AJS.$('#prepare-data-switch').css('border', '')
      AJS.$('#prepare-data-switch').css('box-shadow', '')
      AJS.$('#prepare-data-switch').css('z-index', '')
      AJS.$('#actions-dropdown999').attr('aria-hidden', 'false')
      AJS.$('#actions-dropdown999').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#actions-dropdown999').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
      AJS.$('#actions-dropdown999').css('z-index', '10')
      var inlineDialog = document.getElementById('prepare-data-switch-onboarding')
      inlineDialog.removeAttribute('open')

      var inlineDialog = document.getElementById('actions-dropdown999-onboarding')
      inlineDialog.setAttribute('open', '')
    },
    nextButtonOnSampleReportsOnboarding() {
      AJS.$('#sample-reports-link').css('border', '')
      AJS.$('#sample-reports-link').css('box-shadow', '')
      AJS.$('#sample-reports-link').css('z-index', '100')
      var inlineDialog = document.getElementById('sample-reports-link-onboarding')
      inlineDialog.removeAttribute('open')
      this.$router.push({name: 'sample-reports-onboarding'})
    },
    backButtonOnGetExampleOnboarding() {
      AJS.$('#sample-reports-link').removeClass('active')
      AJS.$('#sample-reports-block').css('border', '')
      AJS.$('#sample-reports-block').css('box-shadow', '')
      AJS.$('#sample-reports-block').css('z-index', '')
      AJS.$('#sample-reports-block-b').css('z-index', '')
      AJS.$('#reports-block').css('border', '')
      AJS.$('#reports-block').css('box-shadow', '')
      AJS.$('#get-example').css('border', '')
      AJS.$('#get-example').css('box-shadow', '')

      var inlineDialog = document.getElementById('get-example-onboarding')
      inlineDialog.removeAttribute('open')

      this.$router.push({name: 'data-sources-onboarding', params: {backFromSampleReports: true}})
    },
    nextButtonOnGetExampleOnboarding() {
      AJS.$('#get-example').css('border', '')
      AJS.$('#get-example').css('box-shadow', '')

      AJS.$('#get-report').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#get-report').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')

      var inlineDialog = document.getElementById('get-example-onboarding')
      inlineDialog.removeAttribute('open')

      var inlineDialog = document.getElementById('get-report-onboarding')
      inlineDialog.setAttribute('open', '')
    },
    backButtonOnGetReportOnboarding() {
      AJS.$('#get-report').css('border', '')
      AJS.$('#get-report').css('box-shadow', '')

      AJS.$('#get-example').css('border', '3px solid rgb(120 111 182)')
      AJS.$('#get-example').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')

      var inlineDialog = document.getElementById('get-report-onboarding')
      inlineDialog.removeAttribute('open')

      var inlineDialog = document.getElementById('get-example-onboarding')
      inlineDialog.setAttribute('open', '')
    },
    nextButtonOnGetReportOnboarding() {
      var inlineDialog = document.getElementById('get-report-onboarding')
      inlineDialog.removeAttribute('open')
      AJS.$('#get-report').css('border', '')
      AJS.$('#get-report').css('box-shadow', '')
      AJS.$('#support-link').css('z-index', '100')
      AJS.$('#sample-reports-link').css('z-index', '')
      this.$router.push({
        name: 'emptyDataSourcesPage',
        params: {providerType: this.$providerType.value, showHelp: true}
      })
    },
    openHelp(value) {
      this.openHelpWindow = value
    },
    closeGlobalOnboarding() {
      AP.dialog.close({
        key: 'global-onboarding'
      })
      this.$isOnboarding.value = false
    },
    updateSidebar(value) {
      this.sidebarWidth = value
    }, skipOnboarding() {
      this.setCookie('skip-onboarding', true)
      AJS.dialog2('#onboarding').hide()
    },
    startOnboarding() {
      AP.require('dialog', function (dialog) {
        dialog.create({
          key: 'global-onboarding',
          url: document.location.href + '/front/global-onboarding',
          width: '100%',
          height: '100%',
          chrome: false
        })
      })
      this.$isOnboarding.value = true
      AJS.dialog2('#onboarding').hide()
    },
    finish() {
      AJS.$('#support-link').css('border', '')
      AJS.$('#support-link').css('box-shadow', '')
      var inlineDialog = document.getElementById('support-link-onboarding')
      inlineDialog.removeAttribute('open')
      AP.dialog.close({finishOnboarding: true})
      this.$isOnboarding.value = false
      this.setCookie('skip-onboarding', true)
    },
    backOnFinish() {
      if (this.$providerType.value === 'POWERBI') {
        AJS.$('#support-link').css('border', '')
        AJS.$('#support-link').css('box-shadow', '')
        var inlineDialog = document.getElementById('support-link-onboarding')
        inlineDialog.removeAttribute('open')
        this.$router.push({name: 'sample-reports-onboarding', params: {isBack: true}})
      } else {
        AJS.$('#prepare-data-switch').css('border', '3px solid rgb(120 111 182)')
        AJS.$('#prepare-data-switch').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
        AJS.$('#prepare-data-switch').css('z-index', '10')
        AJS.$('#prepare-data-switch').css('padding', '2px')
        AJS.$('#data-sources-link-POWERBI').addClass('active')
        AJS.$('#data-sources-block').css('border', '3px solid rgb(120 111 182)')
        AJS.$('#data-sources-block').css('box-shadow', '1px 0px 10px 5px rgb(9 30 66), 1px 1px 1px rgb(9 30 66)')
        AJS.$('#data-sources-block').css('z-index', '10')

        var inlineDialog = document.getElementById('support-link-onboarding')
        inlineDialog.removeAttribute('open')

        var inlineDialog = document.getElementById('prepare-data-switch-onboarding')
        inlineDialog.setAttribute('open', '')
      }
    },
    setCookie(name, value) {
      var expires = ''
      var date = new Date()
      date.setTime(date.getTime() + (360 * 24 * 60 * 60 * 1000))
      expires = '; expires=' + date.toUTCString()
      document.cookie = name + '=' + (value || '') + expires + '; path=/; SameSite=none; Secure'
    }
  }
}
</script>
<style>

@font-face {
  font-family: 'Montserrat-Light'; /*a name to be used later*/
  src: url('https://github.com/JulietaUla/Montserrat/blob/master/fonts/ttf/Montserrat-Light.ttf'); /*URL to font*/
}

body:not(.aui-legacy-focus) .aui-button:focus {
  outline: none !important;
  box-shadow: none !important;
}

.inline-dialog-header {
  display: flex;
  justify-content: space-between !important;
  color: white !important;
}

.aui-button-primary:disabled {
  opacity: 0.5 !important;
}

.aui-dialog2 {
  border-radius: 10px !important;
  box-shadow: black 0px 5px 20px !important;
}

.aui-dialog2-footer {
  border-top: none !important;
  justify-content: center;
  display: flex;
  padding: 0;
  background: #f5f5f5;
  border-bottom-left-radius: 10px !important;
  border-bottom-right-radius: 10px !important;
}

.aui-dialog2-header {
  background: #01334d !important;
  border-bottom: 0 !important;
  border-top-left-radius: 10px !important;
  border-top-right-radius: 10px !important;
}

.aui-dialog2-header-main {
  color: white !important;
  margin: 0 !important;
}

.aui-dialog2-header-close {
  color: white !important;
}

.inline-dialog-header > h3 {
  color: white;
}

.inline-dialog-header .close-button {
  color: white;
  cursor: pointer;
}

#data-sources-table {
  line-height: initial;
  border-collapse: separate !important;
  border-spacing: 0 5px;
}

#data-sources-table-without-prepare {
  line-height: initial;
  border-collapse: separate !important;
  border-spacing: 0 5px;
}

#page-content .long-field {
  max-width: 100%;
}

.onboarding .aui-inline-dialog-contents {
  background: rgb(101, 84, 192) !important;
  border: 2px solid rgb(106 97 169) !important;
  color: white;
  border-radius: 10px !important;
}

.onboarding .aui-button-primary {
  background: rgb(82, 67, 170) !important;
}

.onboarding .aui-button-primary:hover {
  background: #402eac !important;
}

.onboarding h1 h2 h3 h4 {
  color: white !important;
}

.table-block {
  border: 0 !important;
  padding: 10px 0px 2px 0px !important;
  border-radius: 10px !important;
  margin: 10px !important;
  margin-left: 0 !important;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.table-settings-icon {
  margin-right: 10px !important;
}

.table-settings-header {
  margin-top: 10px !important;
  margin-left: 20px !important;
}

.table-settings-block-header {
  border-top: none !important;
  border-bottom: none !important;
  border-left: 1px solid black !important;
  border-right: none !important;
  margin-left: 4px;
}

.table-settings-block-content {
  border-bottom: none !important;
  border-top: none !important;
  border-right: none !important;
  border-left: 1px solid black !important;
  margin-left: 4px;
  padding-left: 30px;
}


.fields-section .content {
  display: none;
}

#audit-content tr {
  border-bottom: 1px solid #dfe1e6;
}

.daterangepicker td, th {
  padding: 0 !important;
}

g.classGroup line {
  stroke: gray !important;
  stroke-width: 1;
}

g.classGroup text {
  fill: black !important;
}

g.classGroup text .title {
  fill: black !important;
  font-size: 14px !important;
}

g.classGroup rect {
  fill: white !important;
  stroke: black !important;
}

.daterangepicker .ranges {
  float: right !important;
}

.daterangepicker .drp-calendar.left {
  padding: 10px !important;
}

.daterangepicker .drp-calendar.right {
  padding: 10px !important;
}

.daterangepicker {
  position: absolute;
  color: inherit;
  background-color: #fff;
  border-radius: 4px;
  border: 1px solid #ddd;
  width: 278px;
  max-width: none;
  padding: 0;
  margin-top: 7px;
  top: 100px;
  left: 20px;
  z-index: 3001;
  display: none;
  font-family: arial;
  font-size: 15px;
  line-height: 1em;
}

.daterangepicker:before, .daterangepicker:after {
  position: absolute;
  display: inline-block;
  border-bottom-color: rgba(0, 0, 0, 0.2);
  content: '';
}

.daterangepicker:before {
  top: -7px;
  border-right: 7px solid transparent;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #ccc;
}

.daterangepicker:after {
  top: -6px;
  border-right: 6px solid transparent;
  border-bottom: 6px solid #fff;
  border-left: 6px solid transparent;
}

.daterangepicker.opensleft:before {
  right: 9px;
}

.daterangepicker.opensleft:after {
  right: 10px;
}

.daterangepicker.openscenter:before {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.openscenter:after {
  left: 0;
  right: 0;
  width: 0;
  margin-left: auto;
  margin-right: auto;
}

.daterangepicker.opensright:before {
  left: 9px;
}

.daterangepicker.opensright:after {
  left: 10px;
}

.daterangepicker.drop-up {
  margin-top: -7px;
}

.daterangepicker.drop-up:before {
  top: initial;
  bottom: -7px;
  border-bottom: initial;
  border-top: 7px solid #ccc;
}

.daterangepicker.drop-up:after {
  top: initial;
  bottom: -6px;
  border-bottom: initial;
  border-top: 6px solid #fff;
}

.daterangepicker.single .daterangepicker .ranges, .daterangepicker.single .drp-calendar {
  float: none;
}

.daterangepicker.single .drp-selected {
  display: none;
}

.daterangepicker.show-calendar .drp-calendar {
  display: block;
}

.daterangepicker.show-calendar .drp-buttons {
  display: block;
}

.daterangepicker.auto-apply .drp-buttons {
  display: none;
}

.daterangepicker .drp-calendar {
  display: none;
  max-width: 270px;
}

.daterangepicker .drp-calendar.left {
  padding: 8px 0 8px 8px;
}

.daterangepicker .drp-calendar.right {
  padding: 8px;
}

.daterangepicker .drp-calendar.single .calendar-table {
  border: none;
}

.daterangepicker .calendar-table .next span, .daterangepicker .calendar-table .prev span {
  color: #fff;
  border: solid black;
  border-width: 0 2px 2px 0;
  border-radius: 0;
  display: inline-block;
  padding: 3px;
}

.daterangepicker .calendar-table .next span {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.daterangepicker .calendar-table .prev span {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.daterangepicker .calendar-table th, .daterangepicker .calendar-table td {
  white-space: nowrap;
  text-align: center;
  vertical-align: middle;
  min-width: 32px;
  width: 32px;
  height: 24px;
  line-height: 24px;
  font-size: 12px;
  border-radius: 4px;
  border: 1px solid transparent;
  white-space: nowrap;
  cursor: pointer;
}

.daterangepicker .calendar-table {
  border: 1px solid #fff;
  border-radius: 4px;
  background-color: #fff;
}

.daterangepicker .calendar-table table {
  width: 100%;
  margin: 0;
  border-spacing: 0;
  border-collapse: collapse;
}

.daterangepicker td.available:hover, .daterangepicker th.available:hover {
  background-color: #eee;
  border-color: transparent;
  color: inherit;
}

.daterangepicker td.week, .daterangepicker th.week {
  font-size: 80%;
  color: #ccc;
}

.daterangepicker td.off, .daterangepicker td.off.in-range, .daterangepicker td.off.start-date, .daterangepicker td.off.end-date {
  background-color: #fff;
  border-color: transparent;
  color: #999;
}

.daterangepicker td.in-range {
  background-color: #ebf4f8;
  border-color: transparent;
  color: #000;
  border-radius: 0;
}

.daterangepicker td.start-date {
  border-radius: 4px 0 0 4px;
}

.daterangepicker td.end-date {
  border-radius: 0 4px 4px 0;
}

.daterangepicker td.start-date.end-date {
  border-radius: 4px;
}

.daterangepicker td.active, .daterangepicker td.active:hover {
  background-color: #357ebd;
  border-color: transparent;
  color: #fff;
}

.daterangepicker th.month {
  width: auto;
}

.daterangepicker td.disabled, .daterangepicker option.disabled {
  color: #999;
  cursor: not-allowed;
  text-decoration: line-through;
}

.daterangepicker select.monthselect, .daterangepicker select.yearselect {
  font-size: 12px;
  padding: 1px;
  height: auto;
  margin: 0;
  cursor: default;
}

.daterangepicker select.monthselect {
  margin-right: 2%;
  width: 56%;
}

.daterangepicker select.yearselect {
  width: 40%;
}

.daterangepicker select.hourselect, .daterangepicker select.minuteselect, .daterangepicker select.secondselect, .daterangepicker select.ampmselect {
  width: 50px;
  margin: 0 auto;
  background: #eee;
  border: 1px solid #eee;
  padding: 2px;
  outline: 0;
  font-size: 12px;
}

.daterangepicker .calendar-time {
  text-align: center;
  margin: 4px auto 0 auto;
  line-height: 30px;
  position: relative;
}

.daterangepicker .calendar-time select.disabled {
  color: #ccc;
  cursor: not-allowed;
}

.daterangepicker .drp-buttons {
  clear: both;
  text-align: right;
  padding: 8px;
  border-top: 1px solid #ddd;
  display: none;
  line-height: 12px;
  vertical-align: middle;
}

.daterangepicker .drp-selected {
  display: inline-block;
  font-size: 12px;
  padding-right: 8px;
}

.daterangepicker .drp-buttons .btn {
  margin-left: 8px;
  font-size: 12px;
  font-weight: bold;
  padding: 4px 8px;
}

.daterangepicker.show-ranges.single.rtl .drp-calendar.left {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.single.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker.show-ranges.rtl .drp-calendar.right {
  border-right: 1px solid #ddd;
}

.daterangepicker.show-ranges.ltr .drp-calendar.left {
  border-left: 1px solid #ddd;
}

.daterangepicker .ranges {
  float: none;
  text-align: left;
  margin: 0;
}

.daterangepicker.show-calendar .ranges {
  margin-top: 8px;
}

.daterangepicker .ranges ul {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 100%;
}

.daterangepicker .ranges li {
  font-size: 12px;
  padding: 8px 12px;
  cursor: pointer;
}

.daterangepicker .ranges li:hover {
  background-color: #eee;
}

.daterangepicker .ranges li.active {
  background-color: #08c;
  color: #fff;
}

/*  Larger Screen Styling */
@media (min-width: 564px) {
  .daterangepicker {
    width: auto;
  }

  .daterangepicker .ranges ul {
    width: 140px;
  }

  .daterangepicker.single .ranges ul {
    width: 100%;
  }

  .daterangepicker.single .drp-calendar.left {
    clear: none;
  }

  .daterangepicker.single .ranges, .daterangepicker.single .drp-calendar {
    float: left;
  }

  .daterangepicker {
    direction: ltr;
    text-align: left;
  }

  .daterangepicker .drp-calendar.left {
    clear: left;
    margin-right: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    border-right: none;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .daterangepicker .drp-calendar.right {
    margin-left: 0;
  }

  .daterangepicker .drp-calendar.right .calendar-table {
    border-left: none;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .daterangepicker .drp-calendar.left .calendar-table {
    padding-right: 8px;
  }

  .daterangepicker .ranges, .daterangepicker .drp-calendar {
    float: left;
  }
}

@media (min-width: 730px) {
  .daterangepicker .ranges {
    width: auto;
  }

  .daterangepicker .ranges {
    float: left;
  }

  .daterangepicker.rtl .ranges {
    float: right;
  }

  .daterangepicker .drp-calendar.left {
    clear: none !important;
  }
}

.icon20 {
  cursor: pointer;
}

.icon20::before {
  font-size: 20px !important;
  margin-top: -10px !important;
}

#mermaid-content {
  zoom: 1;
  text-align: center;
}

.git-form-container {
  min-height: 150px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly
}

#access-section .select2-container {
  max-width: none !important;
  border-radius: 30px !important;
}

#access-section .select2-search-field {
  padding-left: 5px !important;
}

#access-section .select2-choices {
  overflow: hidden !important;
  height: auto !important;
  min-height: 35px !important;
  border-radius: 30px !important;
  background: white !important;
  border: 1px solid #999999 !important;
}

#access-section .select2-input {
  height: 25px !important;
}

#access-section .field-group input::placeholder {
  padding-left: 5px !important;
}

#access-section input {
  padding-left: 10px !important;
}

#access-section label {
  font-size: 14px !important;
  margin-left: 7px !important;
  color: #11324B !important;
}

#access-section .aui-select2-container.select2-container-multi .select2-choices:before {
  right: 15px !important;
  font-size: 28px !important;
  margin-top: -12px !important;
}

#access-section aui-select button {
  background: transparent !important;
  color: black !important;
}

#access-section aui-select button:active {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

#access-section aui-select button:focus {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

#access-section aui-select button:before {
  right: 15px !important;
  font-size: 28px !important;
  margin-top: -10px !important;
}

#access-section .access-table aui-select button:before {
  right: 15px !important;
  font-size: 28px !important;
  margin-top: -13px !important;
}

#access-section .access-table aui-select .text {
  border-radius: 30px !important;
  height: 30px !important;
  background: white !important;
  border: 1px solid #999999 !important;
}

#access-section aui-select .text {
  border-radius: 30px !important;
  height: 35px !important;
  background: white !important;
  border: 1px solid #999999 !important;
}

.access-table tbody tr {
  background: transparent !important;
  border-top: none !important;
}

.access-table tbody tr td {
  vertical-align: middle !important;
}

.access-table tbody tr td:nth-child(1) {
  border-top-left-radius: 30px;
  border-bottom-left-radius: 30px;
  border-top: none !important;
}

.access-table tbody tr td:nth-child(2) {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  border-top: none !important;
}

.access-table tbody:before {
  content: "@";
  display: block;
  line-height: 5px;
  text-indent: -99999px;
}

.access-table thead {
  border-bottom: none !important;
}

.access-table thead th {
  background: transparent !important;
  width: 50%;
  padding: 5px 10px !important;
  border-top: 1px solid #dbdde0;
  border-bottom: 1px solid #dbdde0;
  font-size: 14px !important;
}

.access-table tr td {
  background: white !important;
  border-bottom: none !important;
}

#access-section th td {
  padding: 7px 0 !important;
}

.projects-table .select2-container {
  max-width: none !important;
  height: 35px;
  border-radius: 30px !important;
}

.projects-table .select2-search-field {
  padding-left: 5px !important;
}

.projects-table .select2-choices {
  height: 35px !important;
  border-radius: 30px !important;
  background: white !important;
  border: 1px solid #999999 !important;
}

.projects-table .select2-input {
  height: 25px !important;
}

.projects-table .field-group input::placeholder {
  padding-left: 5px !important;
}

.projects-table input {
  padding-left: 10px !important;
}

.projects-table label {
  font-size: 14px !important;
  margin-left: 7px !important;
  color: #11324B !important;
}

.projects-table .aui-select2-container.select2-container-multi .select2-choices:before {
  right: 15px !important;
  font-size: 28px !important;
  margin-top: -12px !important;
}

.projects-table aui-select button {
  background: transparent !important;
  color: black !important;
}

.projects-table aui-select button:active {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.projects-table aui-select button:focus {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.projects-table aui-select button:before {
  right: 15px !important;
  font-size: 28px !important;
  margin-top: -10px !important;
}

.projects-table aui-select button:before {
  right: 15px !important;
  font-size: 28px !important;
  margin-top: -13px !important;
}

.projects-table .text {
  border: 1px solid #999 !important;
  border-radius: 30px !important;
}

.projects-table aui-select .text {
  border-radius: 30px !important;
  height: 30px !important;
  background: white !important;
  border: 1px solid #999999 !important;
}

.projects-table aui-select .text {
  border-radius: 30px !important;
  height: 35px !important;
  background: white !important;
  border: 1px solid #999999 !important;
}

.projects-table tbody tr {
  background: transparent !important;
  border-bottom: none !important;
  border-top: none !important;
}

.projects-table tbody tr td {
  border-top: none !important;
  padding-left: 20px !important;
  vertical-align: middle !important;
}

.projects-table tbody tr td:nth-child(1) {
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  border-top: none !important;
}

.projects-table tbody tr td:nth-child(5) {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: none !important;
}

.projects-table tbody:before {
  content: "@";
  display: block;
  line-height: 5px;
  text-indent: -99999px;
}

.projects-table thead {
  border-bottom: none !important;
}

.projects-table thead th {
  background: transparent !important;
  width: 50%;
  padding: 5px 10px !important;
  border-top: 1px solid #dbdde0;
  border-bottom: 1px solid #dbdde0;
  font-size: 14px !important;
}

.projects-table tr td {
  background: white !important;
  border-bottom: none !important;
}

.projects-table th td {
  padding: 7px 0 !important;
}

.fields-section .tbody .content {
  margin-top: 0 !important;
}

.info-tempo-icon {
  vertical-align: middle !important;
  margin-top: -2px !important;
  width: 24px !important;
  height: 24px !important;
}

.info-tempo-icon:before {
  font-size: 24px !important;
}

.plugins .select2-container {
  max-width: none !important;
  height: 35px;
  border-radius: 30px !important;
}

.plugins .select2-search-field {
  padding-left: 5px !important;
}

.plugins .select2-choices {
  height: 35px !important;
  border-radius: 30px !important;
  background: white !important;
  border: 1px solid #999999 !important;
}

.plugins .select2-input {
  height: 25px !important;
}

.plugins .field-group input::placeholder {
  padding-left: 5px !important;
}

.plugins input {
  padding-left: 10px !important;
}

.plugins label {
  font-size: 14px !important;
  margin-left: 7px !important;
  color: #11324B !important;
}

.plugins .aui-select2-container.select2-container-multi .select2-choices:before {
  right: 15px !important;
  font-size: 28px !important;
  margin-top: -12px !important;
}

.plugins aui-select button {
  background: transparent !important;
  color: black !important;
}

.plugins aui-select button:active {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.plugins aui-select button:focus {
  border: none !important;
  outline: 0 !important;
  box-shadow: none !important;
}

.plugins aui-select button:before {
  right: 15px !important;
  font-size: 28px !important;
  margin-top: -10px !important;
}

.plugins aui-select button:before {
  right: 15px !important;
  font-size: 28px !important;
  margin-top: -13px !important;
}

.plugins .text {
  border: 1px solid #999 !important;
  border-radius: 30px !important;
}

.plugins aui-select .text {
  border-radius: 30px !important;
  height: 30px !important;
  background: white !important;
  border: 1px solid #999999 !important;
}

.plugins aui-select .text {
  border-radius: 30px !important;
  height: 35px !important;
  background: white !important;
  border: 1px solid #999999 !important;
}

.plugins .tbody .tr {
  background: transparent !important;
  border-bottom: none !important;
  border-top: none !important;
  margin-bottom: 5px !important;
  display: flex;
  position: relative;
}

.subitem-arrow-component {
  margin-left: 4px;
  pointer-events: none;
  width: 26px;
  height: 100%;
  position: absolute;
  z-index: 2;
}

.subitem-arrow-component-top {
  position: absolute;
  top: 0;
  width: 100%;
  height: 50%;
  border-bottom-left-radius: 8px;
}

.subitem-arrow-component-bottom {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 100%;
}

.plugins .tbody .tr .td {
  border-top: none !important;
  padding-left: 20px !important;
  vertical-align: middle !important;
}

.plugins .tbody .tr .td:nth-child(1) {
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  border-top: none !important;
}

.plugins .tbody .tr .td:nth-child(3) {
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  border-top: none !important;
}

.plugins .tbody:before {
  content: "@";
  display: block;
  line-height: 5px;
  text-indent: -99999px;
}

.table .tbody .tr .td {
  word-break: break-all;
  vertical-align: middle;
  border-left: 1px solid #dfe1e6;
  border-bottom: 1px solid #dfe1e6;
  border-top: 1px solid #dfe1e6;
  padding: 7px 10px;
  text-align: left;
  color: #172b4d;
}

.plugins .thead {
  border-bottom: none !important;
}

.plugins .thead .th {
  background: transparent !important;
  width: 50%;
  padding: 5px 10px !important;
  border-top: 1px solid #dbdde0;
  border-bottom: 1px solid #dbdde0;
  font-size: 14px !important;
}

.plugins .tr .td {
  background: white !important;
  border-bottom: none !important;
}

.plugins .th .td {
  padding: 7px 0 !important;
}

aui-spinner {
  margin: auto;
}

#access-config-table tr td:nth-child(1) {
  width: 33%;
}

#access-config-table tr td:nth-child(2) {
  width: 33%;
}

#access-config-table tr td:nth-child(3) {
  width: 33%;
}

.warning-section-button {
  outline: 2px solid red !important;
}

.sidebar-inline .aui-inline-dialog-contents {
  box-shadow: none !important;
  background: #00334d !important;
  color: white !important;
  padding: 10px !important;
  font-size: 14px !important;
  border: 2px solid #1361c4 !important;
}

.sidebar-inline .aui-inline-dialog:before {
  border-bottom-color: white !important;
}

.warning-section-table-prepare tr:first-child td:first-child {
  border-top: 2px solid #d51921 !important;
}

.warning-section-table-prepare tr td:first-child {
  border-left: 2px solid #d51921 !important;
  border-right: 2px solid #d51921 !important;
}

.warning-section-table-prepare tr:last-child td:first-child {
  border-bottom: 2px solid #d51921 !important;
}

.warning-block .warning-inline-dialog .aui-inline-dialog:before, aui-inline-dialog:before {
  border-bottom-color: #d51921 !important;
}

.warning-block .warning-inline-dialog .aui-inline-dialog .aui-inline-dialog-contents, .aui-inline-dialog-contents {
  border: 2px solid red !important;
  color: black;
  background: #ffebe6 !important;
  box-shadow: 0px 5px 20px grey !important;
}

.prepare-data-block {
  margin-right: 5px;
}

#data-sources-table-without-prepare th {
  vertical-align: middle;
  border-left: 1px solid rgb(223, 225, 230);
}

#data-sources-table-without-prepare th:nth-child(1) {
  border-left: none !important;
}

#data-sources-table-without-prepare th:nth-child(7) {
  border-left: none !important;
}

#data-sources-table-without-prepare th:nth-child(8) {
  border-left: none !important;
}

#data-sources-table-without-prepare thead {
  border-bottom: none !important;
}

tbody tr:hover {
  background: white !important;
  background-color: white !important;
}

.connectors-block tbody tr:hover td {
  background: white !important;
  background-color: white !important;
}

.connectors-block tbody tr:focus-within td {
  background: white !important;
  background-color: white !important;
}

table.aui > tbody > tr:hover > td {
  background: white !important;
}

table.aui > tbody > tr:focus-within > td {
  background: white !important;
}

.connectors-block tr td {
  vertical-align: middle !important;
  border-left: 3px solid rgb(244, 245, 245);
  border-bottom: none !important;
}

.filter-connector #filter-users {
  background-color: white !important;
  border-radius: 10px !important;
}

.connectors-block .aui-button-primary {
  background: #8F9AAD;
  border: 1px solid #7F8CA3;
  border-radius: 10px;
}

.aui-button.aui-button-primary {
  border-radius: 10px !important;
}

.aui-button {
  background: #8f9aad !important;
  background-color: #8f9aad !important;
  color: white !important;
  border-radius: 10px !important;
}

.aui-button-link {
  background-color: transparent !important;
  color: #0052cc !important;
}

.select2-choice {
  border-radius: 10px !important;
}

.select2-choices {
  border-radius: 10px !important;
}

.select2-container {
  border-radius: 10px !important;
}

aui-select .aui-button {
  border-bottom-left-radius: 0px !important;
  border-top-left-radius: 0px !important;
}

aui-select input {
  border-radius: 10px !important;
}

.aui-button .aui-icon {
  color: white !important;
}

.aui-button-primary {
  border-radius: 10px;
  background-color: #0052cc !important;
}

.create-block .field-group input {
  border-radius: 10px !important;
}

/*.create-block .field-group input::placeholder {*/
/*  padding-left: 10px!important;*/
/*  color: gray!important;*/
/*}*/

.create-block .field-group textarea {
  border-radius: 10px !important;
}

.connectors-block .aui-button-primary:hover {
  background: #6b778c;
  border: 1px solid #7F8CA3;
}

.aui-toggle-input.indeterminate-checked + .aui-toggle-view, .aui-toggle-input:checked:enabled + .aui-toggle-view {
  background-color: #00875a !important;
}

.filter-section-right .aui-toggle-view {
  background-color: #8f9aad !important;
}

.connectors-block thead th {
  background-color: #ffffff00;
  border-left: none !important;
  align-items: center;
  border-top: 1px solid #DBDDE0;
  border-bottom: 1px solid #DBDDE0;
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  font-size: 14px !important;
  color: #01334d !important;
  text-align: center;
}

.connectors-block tbody:before {
  content: "@";
  display: block;
  line-height: 5px;
  text-indent: -99999px;
}

.connectors-block tr:nth-child(1) td {
  border-top: none !important;
}

#data-sources-table-without-prepare tr td:nth-child(1) {
  border-left: none !important;
  word-break: break-all;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  padding-left: 20px;
  width: 28%;
}

#data-sources-table-without-prepare tr td:nth-child(3) {
  width: 22%;
}

#data-sources-table-without-prepare tr td:nth-child(4) {
  width: 30%;
}

#data-sources-table-without-prepare tr td:nth-child(5) {
  width: 10%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-right: 20px;
}

#data-sources-table-without-prepare tr td:nth-child(6) {
  border-left: none !important;
}

#data-sources-table-without-prepare tr td:nth-child(7) {
  border-left: none !important;
}

.not-prepared {
  color: #BC7200;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  font-size: 12px;
}

.prepared {
  color: #68BC6D;
  font-weight: 700;
  margin-left: 10px;
  margin-right: 10px;
  text-align: center;
  font-size: 12px;
}

#data-sources-table tr td:nth-child(1) {
  border-left: none !important;
  border-top-left-radius: 20px !important;
  border-bottom-left-radius: 20px !important;
  padding-left: 20px;
  width: 12%;
}

#data-sources-table tr td:nth-child(2) {
  word-break: break-all;

  width: 25%;
}

#data-sources-table tr td:nth-child(4) {
  width: 22%;
}

#data-sources-table tr td:nth-child(5) {
  width: 30%;
}

#data-sources-table tr td:nth-child(6) {
  width: 10%;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  padding-right: 20px;
}

#data-source-permissions-table tr td:nth-child(1) {
  width: 33%;
}

#data-source-permissions-table tr td:nth-child(2) {
  width: 33%;
}

#data-source-permissions-table tr td:nth-child(3) {
  width: 33%;
}

.fields-section .caption {
  border-radius: 10px;
  background: #f4f5f7;
  padding: 5px 15px;
  border-bottom: 1px solid #c3c3c3;
  margin-top: 0 !important;
  font-size: 1.4285714285714286em;
  font-style: inherit;
  line-height: 1.2;
  color: #172B4D;
  font-weight: 500;
  letter-spacing: -0.008em;
  margin-bottom: 0px;
  text-align: left;
}

.fields-section table.aui > tbody > tr {
  border: 1px solid #dfe1e6;
}

.fields-section table.aui > tbody > tr > td {
  width: 25%;
}

.fields-section fieldset.group {
  width: 97% !important;
  display: inline-block !important;
}

.icon-arrow {
  float: left !important;
  display: inline-block;
  margin-top: 5px !important;
}

/* Style the buttons that are used to open and close the accordion panel */
.accordion {
  cursor: pointer;
  text-align: left;
  border: none;
  outline: none;
}

/* Style the accordion panel. Note: hidden by default */
.panel {
  border: none !important;
  background-color: white;
  display: none;
  overflow: hidden;
  margin-left: 20px !important;
}

.fields-section {
  /*max-height: 800px;*/
  overflow: auto;
}

#best-bi-nav-content .aui-nav-heading {
  color: #172b4d !important;
  font-size: 15px;
  margin-top: 10px;
}

.active-nav {
  background-color: #deebff;
  color: #42526e;
  border-radius: 5px;
}

.find-field {
  background: #2684ff;
  color: WHITE !important;
  font-weight: 500 !important;
  padding: 4px;
  border-radius: 3px;
}

.no-find-table {
  display: none
}

body {
  background: #ffffff !important;
}
</style>
