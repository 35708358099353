<script>
import Vue from 'vue'
import {collapsed} from './state'

export default {
  props: [
    'to',
    'icon',
    'collapsed',
    'providertype'
  ],
  methods: {
    onboarding(){
      return this.$isOnboarding.value
    },
    hoverLink(to) {
      if (collapsed) {
        document.getElementById(to).open = true
      }
    },
    notHoverLink(to) {
      if (collapsed) {
        document.getElementById(to).open = false
      }
    },
    routeToLink(element) {
      var target = element.target
      $('.sidebar a').each(function (e) {
        $(this).removeClass('active')
      })
      if ($(target).is('a')) {
        $(target).addClass('active')
      } else {
        $(target.parentElement).addClass('active')
      }
      this.$router.push({name: this.to, params: {providerType: this.providertype}}).catch(() => {
      })
    },
    openHelpDialog() {
      AJS.$('#help-dialog').show()
    }
  }
}
</script>

<template>
  <div>
    <a  :style="onboarding()?{'pointer-events':'none'}:{},collapsed?{'width':'30px','margin-right':'auto','margin-left':'auto','margin-bottom':'10px', 'justify-content':'center'}:onboarding()?{'pointer-events':'none'}:{}"
       v-if="to!=='support' && to!=='documentation'" class="link with-onboarding"
       :aria-controls="to+'-link-onboarding'+(this.providertype?'-'+this.providertype:'')" @click="this.routeToLink"
       :id="to+'-link'+(this.providertype?'-'+this.providertype:'')"
       :class="{active: (this.to==='data-sources' && this.providertype==='POWERBI') }">
      <img v-if="this.to==='data-sources' && this.providertype==='POWERBI'" src="../../assets/powerbi.svg"
           :style="collapsed?{'width': '25px'}:{'width': '15px'}"
           @mouseover="hoverLink(to)" @mouseleave="notHoverLink(to)" data-aui-trigger :aria-controls="to"/>
      <img v-else-if="this.to==='data-sources-tableau' && this.providertype==='TABLEAU'" src="../../assets/tableau.svg"
           :style="collapsed?{'width': '25px'}:{'width': '15px'}" @mouseover="hoverLink(to)"
           @mouseleave="notHoverLink(to)" data-aui-trigger
           :aria-controls="to"/>
      <i :style="collapsed?{'font-size': '20px'}:{'font-size': '15px'}" v-else class="icon" :class="icon"
         @mouseleave="notHoverLink(to)" @mouseover="hoverLink(to)" data-aui-trigger
         :aria-controls="to"/>
      <span v-if="!collapsed" style="margin-left: 10px">
        <slot/>
      </span>
      <aui-inline-dialog class="sidebar-inline" :id="to">
        <slot/>
      </aui-inline-dialog>
    </a>


    <a :style="onboarding()?{'pointer-events':'none'}:{},collapsed?{'width':'30px','margin-right':'auto','margin-left':'auto','margin-bottom':'10px', 'justify-content':'center'}:onboarding()?{'pointer-events':'none'}:{}"
       v-else-if="to==='documentation'"
       :aria-controls="to+'-link-onboarding'+(this.providertype?'-'+this.providertype:'')"
       :id="to+'-link'+(this.providertype?'-'+this.providertype:'')" target="_blank"
       class="link with-onboarding" :href="$documentationLink.value"
       :class="{active: (this.to==='data-sources' && this.providertype==='POWERBI') }">
      <i :style="collapsed?{'font-size': '20px'}:{'font-size': '15px'}" class="icon" :class="icon"
         @mouseleave="notHoverLink(to)" @mouseover="hoverLink(to)" data-aui-trigger
         :aria-controls="to"/>
      <span v-if="!collapsed" style="margin-left: 10px">
        <slot/>
      </span>
      <aui-inline-dialog class="sidebar-inline" :id="to">
        <slot/>
      </aui-inline-dialog>
    </a>

  </div>
</template>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.1s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}

.link {
  display: flex;
  align-items: center;
  cursor: pointer;
  position: relative;
  font-weight: 400;
  user-select: none;
  margin: 0.1em 0;
  padding: 0.4em;
  border-radius: 0.25em;
  height: 1.5em;
  color: white;
  text-decoration: none;
}

.link:hover {
  background-color: #003381;
}

.link.active {
  background-color: #0052cc;
}

.link .icon {
  flex-shrink: 0;
}
</style>
