import { render, staticRenderFns } from "./VaBreadcrumb.vue?vue&type=template&id=f2c0dc00&scoped=true&"
import script from "./VaBreadcrumb.vue?vue&type=script&lang=js&"
export * from "./VaBreadcrumb.vue?vue&type=script&lang=js&"
import style0 from "../style/_reset.scss?vue&type=style&index=0&id=f2c0dc00&lang=scss&scoped=true&"
import style1 from "./VaBreadcrumb.vue?vue&type=style&index=1&lang=scss&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2c0dc00",
  null
  
)

export default component.exports