export default {
    validateUrl(url) {
        let regex = /^(http|https|git|ssh)/
        return url.length > 10 && url.match(regex)
    },
    escapeHtml(input) {
        if (input !== null || input !== '' || input !== undefined || input !== 'undefined') {
            return String(input)
                .replace(/javascript:/gi, "")
                .replace(/&/g, '&amp;')
                .replace(/"/g, '&quot;')
                .replace(/'/g, '&#39;')
                .replace(/</g, '&lt;')
                .replace(/>/g, '&gt;')
        }
        return ''
    },
    closeDialog(id) {
        AJS.dialog2('#' + id).hide()
    }

}
