import $ from './jquery';
import amdify from './internal/amdify';
import globalize from './internal/globalize';
import layerWidget from './layer';
import widget from './internal/widget';
import keyCode from './key-code';
import { I18n } from './i18n';

var defaults = {
    'aui-focus': 'false', // do not focus by default as it's overridden below
    'aui-blanketed': 'true'
};

function applyDefaults($el) {
    $.each(defaults, function (key, value) {
        var dataKey = 'data-' + key;
        if (!$el[0].hasAttribute(dataKey)) {
            $el.attr(dataKey, value);
        }
    });
}

function Dialog2(selector) {
    if (selector) {
        this.$el = $(selector);
    } else {
        this.$el = $(`
        <section role="dialog" class="aui-layer aui-dialog2 aui-dialog2-medium" aria-hidden="true">
            <header class="aui-dialog2-header">
                <h2 class="aui-dialog2-header-main"></h2>
                <button class="aui-dialog2-header-close" aria-label="${I18n.getText('aui.words.close')}">
                    <span class="aui-icon aui-icon-small aui-iconfont-close-dialog"></span>
                </button>
            </header>
            <div class="aui-dialog2-content"></div>
            <footer class="aui-dialog2-footer"></footer>
        </section>`);
    }
    applyDefaults(this.$el);
}

Dialog2.prototype.on = function (event, fn) {
    layerWidget(this.$el).on(event, fn);
    return this;
};

Dialog2.prototype.off = function (event, fn) {
    layerWidget(this.$el).off(event, fn);
    return this;
};

Dialog2.prototype.show = function () {
    layerWidget(this.$el).show();
    return this;
};

Dialog2.prototype.hide = function () {
    layerWidget(this.$el).hide();
    return this;
};

Dialog2.prototype.remove = function () {
    layerWidget(this.$el).remove();
    return this;
};

Dialog2.prototype.isVisible = function () {
    return layerWidget(this.$el).isVisible();
};

var dialog2Widget = widget('dialog2', Dialog2);

dialog2Widget.on = function (eventName, fn) {
    layerWidget.on(eventName, '.aui-dialog2', fn);
    return this;
};

dialog2Widget.off = function (eventName, fn) {
    layerWidget.off(eventName, '.aui-dialog2', fn);
    return this;
};

/* Live events */

$(document).on('click keydown', '.aui-dialog2-header-close', function(e) {
    const shouldHandle = e.type === 'click' || e.keyCode === keyCode.ENTER || e.keyCode === keyCode.SPACE;
    if (shouldHandle) {
        e.preventDefault();
        dialog2Widget($(e.target).closest('.aui-dialog2')).hide();
    }
});

dialog2Widget.on('show', function (e, $el) {
    // prevent inability to focus the close button.
    $el.find('.aui-dialog2-header-close').attr('tabindex', 0);

    // find appropriate content to focus, in order of importance of the content.
    var focusSelector = $el[0].hasAttribute('data-aui-focus-selector') && $el.attr('data-aui-focus-selector');
    if (focusSelector) {
        var $elementToFocus = $el.find(focusSelector);
        if ($elementToFocus.length > 0) {
            return $elementToFocus.first().focus();
        }
    }

    var selectors = ['.aui-dialog2-content', '.aui-dialog2-footer', '.aui-dialog2-header'];
    var $selected;
    selectors.some(function (selector) {
        $selected = $el.find(selector).find(':aui-tabbable');
        return $selected.length;
    });
    $selected && $selected.first().focus();
});

dialog2Widget.on('hide', function (e,$el) {
    var layer = layerWidget($el);

    if ($el.data('aui-remove-on-hide')) {
        layer.remove();
    }
});

amdify('aui/dialog2', dialog2Widget);
globalize('dialog2', dialog2Widget);
export default dialog2Widget;
