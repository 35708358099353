import axios from 'axios'
import Vue from "vue";
import router from '../router'

function getCookie(name) {

    if (!document.cookie) {
        return null
    }

    const xsrfCookies = document.cookie.split(';')
        .map(c => c.trim())
        .filter(c => c.startsWith(name + '='))

    if (xsrfCookies.length === 0) {
        return null
    }
    return decodeURIComponent(xsrfCookies[0].split('=')[1])
}

const csrfToken = getCookie('XSRF-TOKEN')

const instance = axios.create({
    baseURL: '/api',
    headers: {'X-XSRF-TOKEN': csrfToken}
})
export const AXIOS = instance
AXIOS.interceptors.request.use((config) => {
    config.params = {lic: Vue.prototype.$isLicensed.value}
    return config
})

AXIOS.interceptors.response.use(function (response) {
    return response;
}, function (error) {
    if (error) {
        if (error.response) {
            if (error.response.status === 402) {
                AJS.dialog2('#onboarding').hide()
                router.push({name: "license"})
            }
        }
    }
    return Promise.reject(error);
});

export default {
    preview(items) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/preview', items, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    savePrepareDataActive(active) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/prepareDataSettings/' + active, {}, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getAllPermissions() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/accessConfig/getAll', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getAllEmbeddedReportsAccess() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/embeddedReportAccess/getAll', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    hasAccessToEditEmbeddedReports() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/embeddedReportAccess/hasAccessToEdit', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getAllPermissionsForDataSource(dataSourceId) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/dataSourcePermission/' + dataSourceId, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    addAccessConfig(body) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/accessConfig/addNew', body, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    addEmbeddedReportsAccess(body) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/embeddedReportAccess/addNew', body, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    addAccessForOrganizationExport(body) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/organization/access', body, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getAccessForOrganizationExport() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/organization/access', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    hasOrganizationKey() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/organization/hasKey', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    deleteAccessForOrganizationExport(userId) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.delete('/organization/access/' + userId, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    deletePermission(permission) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/accessConfig/delete', permission, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    deleteEmbeddedReportsAccess(permission) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/embeddedReportAccess/delete', permission, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    addAccessConfigForDataSource(body, dataSourceId) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/dataSourcePermission/add/' + dataSourceId, body, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    updatePermissionForDataSource(permission, dataSourceId) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/dataSourcePermission/update/' + dataSourceId, permission, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    deletePermissionForDataSource(permission, dataSourceId) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/dataSourcePermission/delete/' + dataSourceId, permission, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    updatePermission(permission) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/accessConfig/update', permission, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    updateEmbeddedReportsAccess(permission) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/embeddedReportAccess/update', permission, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    prepareData(hash) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/prepareData', {hash: hash}, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    refreshPrepareData(hash) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/refreshPrepareData', {hash: hash}, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getPrepareData(hash) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/getPrepareData/' + hash, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getPreviewData(dataSourceId) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('preview/' + dataSourceId, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getDataSources(providerType, filterType, filterUsers) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/data-sources?dataSourceType=' + providerType + '&filterType=' + filterType + '&users=' + filterUsers, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    hasTokens() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/tokens', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    refreshingFields() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/data-sources/refreshFields', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },

    getDisabledProjectsObj() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/exportSettings/disabledProjectsObjs', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getEmptyDataSourceInformation() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/data-sources/create-data-source', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getSampleReport(reportType) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/sampleReports/' + reportType, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },

    sendFeedback(request) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/feedback', request, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    userWithAccount() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/pluginUser/userWithAccount', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getClientKey() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/tempo/getClientKey', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },

    getPluginActions() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/admin/apps', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    pluginAction(plugin, enabled) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/admin/apps', {plugin: plugin, enabled: enabled}, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    projectAction(request) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/exportSettings/projects', request, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getDisabledProjects() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/exportSettings/disabledProjects', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    enableAllProjects(action) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/exportSettings/enableAllProjects/' + action, {}, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getEnableAllProjects() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/exportSettings/enableAllProjects', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    deleteDataSource(dataSourceId) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.delete('/data-sources/' + dataSourceId, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    restoreDataSource(dataSourceId) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/data-sources/restore/' + dataSourceId, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    cloneDataSource(dataSourceHash) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/data-sources/clone', {hash: dataSourceHash}, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    saveInsightToken(tempoToken) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/insight/saveToken?token=' + tempoToken, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    saveTm4jToken(tokentm4j) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/tm4j/saveToken', {token: tokentm4j}, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    saveZephyrSquadToken(accessKey, secretKey) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/zephyr_squad/saveToken', {
                    accessKey: accessKey,
                    secretKey: secretKey
                },
                {
                    headers: {
                        'Authorization':
                            'JWT ' + token
                    }
                }
            )
        })
    },
    saveProfieldsToken(apiId, apiKey) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/profields/saveToken', {apiId: apiId, apiKey: apiKey}, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    saveQtm4jToken(apiKey) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/qtm4j/saveToken', {apiKey: apiKey}, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    saveXrayToken(request) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/xray/saveToken', request, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    saveTempoClient(request) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/tempo/saveClient', request, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getAllAuthors() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/data-sources/allAuthors', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    isAdmin() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/pluginUser/isAdmin', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getDataSourcesCount(providerType, filterUsers, filterType) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/data-sources/count?dataSourceType=' + providerType + '&filterType=' + filterType + '&users=' + filterUsers, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    saveCredential(request) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/pluginUser', request, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    saveOrganizationAPIKey(request) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/organization/key', request, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    getMergeTables(id) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/merge?id=' + id, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },

    getCacheConfiguration(dataSourceId) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/cacheConfig?dataSourceId=' + dataSourceId, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    saveCacheConfiguration(dataSourceId, request) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/cacheConfig/save?dataSourceId=' + dataSourceId, request, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    saveDataSource(request) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/data-sources/save', request, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    userHasAccess() {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/accessConfig/userHasAccess', {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    embeddedReportsByIssueId(issueId) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.get('/embedded-reports/issue/' + issueId, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    deleteEmbeddedReportForIssues(id) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.delete('/embedded-reports/issue/' + id, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    },
    addEmbeddedReportsForIssue(body) {
        return AP.context.getToken().then(function (token) {
            return AXIOS.post('/embedded-reports/issue', body, {
                headers: {
                    'Authorization': 'JWT ' + token
                }
            })
        })
    }
}
