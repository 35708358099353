import Vue from 'vue'
import App from './App'
import router from './router'
import '@atlassian/aui/dist/aui/aui-css-deprecations'
import '@atlassian/aui/dist/aui/aui-prototyping.css'
import '@atlassian/aui/dist/aui/aui-prototyping'
import Va from 'vue-atlas'
import 'vue-atlas/dist/vue-atlas.css'
import VueClipboard from 'vue-clipboard2'
import VueYouTubeEmbed from 'vue-youtube-embed'
import VueCookies from 'vue-cookies';
import VueGtag from "vue-gtag";
import utils from '../src/components/utils'


import VueCompositionAPI from '@vue/composition-api'
import httpClient from "@/components/httpClient";

Vue.use(VueCookies);

Vue.use(VueCompositionAPI)

Vue.use(VueYouTubeEmbed)


Vue.use(VueGtag, {
    config: {
        id: 'UA-238357841-1',
        cookie_flags: 'secure;samesite=none',
        params: {
            cookie_flags: 'secure;samesite=none'
        }
    }
})

Vue.use(VueClipboard)
Vue.use(Va, 'en') // or 'es', 'fr', 'ru'
var Lang = require('vuejs-localization')
Vue.prototype.$hostname = 'https://' + window.location.hostname
Vue.prototype.$documentationLink = {value: process.env.VUE_APP_DOCUMENTATION_LINK}
Vue.prototype.$dataPreparationLink = {value: process.env.VUE_APP_DATA_PREPARATION_LINK}
Vue.prototype.$supportLink = {value: process.env.VUE_APP_SUPPORT_LINK}
Vue.prototype.$pluginKey = {value: process.env.VUE_APP_PLUGIN_KEY}
Vue.prototype.$pluginName = {value: process.env.VUE_APP_PLUGIN_NAME}
Vue.prototype.$pluginType = {value: process.env.VUE_APP_PLUGIN_TYPE}
Vue.prototype.$providerType = {value: process.env.VUE_APP_PROVIDER_TYPE}
Vue.prototype.$userGuide = {value: process.env.VUE_APP_DATA_USER_GUIDE_LINK}
Vue.prototype.$isOnboarding = {value: false}
Vue.prototype.$isDashboardDialog = {value: false}
Vue.prototype.$onboardingCount = {value: 21}
Vue.prototype.$isLicensed = {value: 'active'}
Vue.prototype.$isAdmin = {value: null}
Vue.prototype.$isDashboard = {value: false}
Vue.prototype.$utils = utils

const openHelpDialog = Vue.observable({openHelpDialog: {}})
export const isLic = "active"

Object.defineProperty(Vue.prototype, '$openHelpDialog', {
    get() {
        return openHelpDialog.openHelpDialog
    },

    set(value) {
        openHelpDialog.openHelpDialog = value
    }
})

Vue.use(Lang)

new Vue({
    el: '#app',
    router,
    components: {App},
    render: h => h(App),
    template: '<App/>',
    beforeCreate() {
        var that = this
        let redirectedFrom = this.$route.params.pathMatch
        let fullPath = this.$route.fullPath.replaceAll('/view-powerbi-connectors', '')
            .replaceAll('/front/powerbi-connectors', '')
            .replaceAll('/view-tableau-connectors', '')
            .replaceAll('/view-gds-connectors', '')
            .replaceAll('/view-qlik-connectors', '')
            .replaceAll('/view-global-onboarding', '')
        let urlSearchParams = new URLSearchParams(fullPath);
        if (redirectedFrom.includes('/view-dashboard')) {
            this.$isDashboard.value = true
            this.$router.push({
                name: 'secure-embed-dashboard',
                params: {
                    dashboardItemId: urlSearchParams.get("dashboardItemId"),
                    dashboardId: urlSearchParams.get("dashboardId")
                }
            })
        } else {
            var expires = ''
            var date = new Date()
            date.setTime(date.getTime() + (360 * 24 * 60 * 60 * 1000))
            expires = '; expires=' + date.toUTCString()
            document.cookie = 'testCookie=' + ('true' || '') + expires + '; path=/; SameSite=none; Secure'


            const xsrfCookies = document.cookie.split(';')
                .map(c => c.trim())
                .filter(c => c.startsWith('testCookie='))

            if (xsrfCookies.length > 0) {
                let testCookie = xsrfCookies[0].split('=')[1];
                if (testCookie) {


                    let xdm_c = urlSearchParams.get('xdm_c');
                    if (xdm_c.includes('dev') || xdm_c.includes('staging') || xdm_c.includes('test')) {
                        this.$isLicensed.value = 'active'
                    } else {
                        this.$isLicensed.value = urlSearchParams.get('lic');
                    }
                    let user_is_admin = urlSearchParams.get('user_is_admin')

                    if (user_is_admin === '' || user_is_admin === undefined || user_is_admin === null) {
                        setTimeout(function (e) {
                            httpClient.isAdmin().then(function (e) {
                                user_is_admin = e.data
                                console.log("user is admin empty")
                                if (user_is_admin) {
                                    that.$onboardingCount.value = that.$providerType.value === 'POWERBI' ? 21 : 18
                                    that.$isAdmin.value = true
                                } else {
                                    that.$onboardingCount.value = that.$providerType.value === 'POWERBI' ? 14 : 11
                                    that.$isAdmin.value = false
                                }
                            })
                        }, 1000)
                    } else {
                        if (user_is_admin === 'true') {
                            this.$onboardingCount.value = this.$providerType.value === 'POWERBI' ? 21 : 18
                            this.$isAdmin.value = true
                            console.log("is admin")
                        } else {
                            this.$onboardingCount.value = this.$providerType.value === 'POWERBI' ? 14 : 11
                            this.$isAdmin.value = false
                            console.log("is not admin")
                        }
                    }
                    if (this.$isLicensed.value !== 'active') {
                        this.$router.push({name: 'license'})
                    } else {
                        if (redirectedFrom.includes('/view-powerbi-connectors') || redirectedFrom.includes('/front/powerbi-connectors')) {
                            this.$router.push({name: 'data-sources', params: {providerType: 'POWERBI'}})
                        } else if (redirectedFrom.includes('/view-tableau-connectors')) {
                            this.$router.push({name: 'data-sources', params: {providerType: 'TABLEAU'}})
                        } else if (redirectedFrom.includes('/view-gds-connectors')) {
                            this.$router.push({name: 'data-sources', params: {providerType: 'GDS'}})
                        } else if (redirectedFrom.includes('/view-qlik-connectors')) {
                            this.$router.push({name: 'data-sources', params: {providerType: 'QLIK'}})
                        } else if (redirectedFrom.includes('/view-projects-dashboard')) {
                            this.$isDashboard.value = true
                            this.$router.push({name: 'projects-dashboard'})
                        } else if (redirectedFrom.includes('/view-issues-dashboard-dialog')) {
                            var issueId = urlSearchParams.get('issueId');
                            this.$isDashboard.value = true
                            this.$isDashboardDialog.value = true
                            this.$router.push({name: 'issues-dashboard', params: {issueId: issueId}})
                        } else if (redirectedFrom.includes('/view-issues-dashboard')) {
                            var issueId = urlSearchParams.get('issueId');
                            this.$isDashboard.value = true
                            this.$router.push({name: 'issues-dashboard', params: {issueId: issueId}})
                        } else if (redirectedFrom.includes('/view-getting-started')) {
                            this.$router.push({name: 'getting-started'})
                        } else if (redirectedFrom.includes('/view-global-onboarding')) {
                            this.$isOnboarding.value = true
                            this.$router.push({
                                name: 'emptyDataSourcesPage',
                                params: {providerType: this.$providerType.value}
                            })
                        }
                    }
                } else {
                    this.$router.push({name: 'no-cookie-safari'})
                }
            } else {
                this.$router.push({name: 'no-cookie-safari'})
            }
        }

    }
}).$mount('#app')
